var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-0 py-lg-4",staticStyle:{"margin":"0 !important"},attrs:{"elevation":"1"}},[_c('v-card-title',{staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',[_c('v-col',{staticClass:"text-lg-left text-center d-flex align-center",attrs:{"cols":"8","sm":"10","md":"10","lg":"10","xl":"10"}},[_c('v-icon',{staticStyle:{"color":"#0177d4 !important"},attrs:{"large":"","id":"menu-activator"},on:{"click":_vm.goBack}},[_vm._v(" mdi-arrow-left-bold ")]),_c('h5',[_vm._v(_vm._s(_vm.item.ListName))])],1),_c('v-col',{staticClass:"d-flex align-end items-end justify-end",attrs:{"cols":"4","sm":"2","md":"2","lg":"2","xl":"2"}},[_c('FormElement',{key:"ENTRY_IS_ACTIVE",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.isActive'),"rules":"required","topLabel":true,"labelShown":false,"lg":12,"formType":{
            name: 'checkbox',
          },"onChange":() => {
              _vm.updateActiveStatus();
            },"placeholder":_vm.$t('labels.isActive')},model:{value:(_vm.item.isActive),callback:function ($$v) {_vm.$set(_vm.item, "isActive", $$v)},expression:"item.isActive"}})],1)],1)],1),_c('v-divider'),_c('v-dialog',{attrs:{"content-class":"short-content","max-width":"400"},model:{value:(_vm.updateExpiryDateModal),callback:function ($$v) {_vm.updateExpiryDateModal=$$v},expression:"updateExpiryDateModal"}},[_c('v-card',{staticClass:"popup-card",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"popupHeadline",staticStyle:{"font-size":"24px"}},[_c('div',{staticClass:"text-center popupHeadline",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('labels.expiryDate'))+" ")])]),_c('v-divider',{staticClass:"mb-4"}),_c('ValidationObserver',{ref:"expiryDateObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(() => {
              _vm.updateExpiryDate();
            })}}},[_c('v-card-text',[_c('FormElement',{key:"ENTRY_CURRENT_EXPIRY_DATE",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.expiryDate'),"rules":"required","formLabel":_vm.$t('labels.currentExpiryDate'),"lg":12,"cols":11,"disabled":true,"topLabel":true,"labelShown":false,"formType":{
                name: 'date',
                dateMode: 'date',
                backgroundColor: '#E6E6E6',
              },"placeholder":_vm.$t('labels.expiryDate')},model:{value:(_vm.item.ExpiryDate),callback:function ($$v) {_vm.$set(_vm.item, "ExpiryDate", $$v)},expression:"item.ExpiryDate"}}),_c('FormElement',{key:"ENTRY_NEW_EXPIRY_DATE",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.expiryDate'),"rules":"required","formLabel":_vm.$t('labels.newExpiryDate'),"lg":12,"cols":11,"topLabel":true,"labelShown":false,"formType":{
                name: 'date',
                dateMode: 'date',
              },"placeholder":_vm.$t('labels.expiryDate')},model:{value:(_vm.form.ExpiryDate),callback:function ($$v) {_vm.$set(_vm.form, "ExpiryDate", $$v)},expression:"form.ExpiryDate"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"#0077d5","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"medium"},attrs:{"elevation":"0","type":"submit","loading":_vm.saveLoading}},[_vm._v(_vm._s(_vm.$t('buttons.save')))]),_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"var(--red)","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"medium"},attrs:{"elevation":"0"},on:{"click":function($event){_vm.updateExpiryDateModal = false}}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))])],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.updateNameModal),callback:function ($$v) {_vm.updateNameModal=$$v},expression:"updateNameModal"}},[_c('v-card',{staticClass:"popup-card",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"popupHeadline",staticStyle:{"font-size":"24px"}},[_c('div',{staticClass:"text-center popupHeadline",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$t('labels.listName'))+" ")])]),_c('v-divider',{staticClass:"mb-4"}),_c('ValidationObserver',{ref:"nameObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(() => {
              _vm.updateName();
            })}}},[_c('v-card-text',[_c('FormElement',{key:"ENTRY_NAME",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.name'),"rules":"required","topLabel":true,"labelShown":false,"lg":12,"formType":{
                name: 'text',
              },"placeholder":_vm.$t('labels.name')},model:{value:(_vm.form.ListName),callback:function ($$v) {_vm.$set(_vm.form, "ListName", $$v)},expression:"form.ListName"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"#0077d5","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"medium"},attrs:{"elevation":"0","type":"submit","loading":_vm.saveLoading}},[_vm._v(_vm._s(_vm.$t('buttons.save')))]),_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"var(--red)","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"medium"},attrs:{"elevation":"0"},on:{"click":() => {
                  _vm.updateNameModal = false;
                  _vm.form.ListName = _vm.item.ListName;
                }}},[_vm._v(_vm._s(_vm.$t('buttons.cancel')))])],1)],1)]}}])})],1)],1),_c('ConfirmationModal',{attrs:{"title":_vm.$t('labels.warning'),"message":_vm.$t('messages.changeTheExcelFile'),"confirm":_vm.finishExcelFileOperation},model:{value:(_vm.confirmationModal),callback:function ($$v) {_vm.confirmationModal=$$v},expression:"confirmationModal"}}),(_vm.priceData)?_c('ValidationObserver',{ref:"addChartEntryObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"mt-5",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.update)}}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0px","margin":"0px"}},[_c('v-row',{staticClass:"px-4 pt-4"},[_c('v-col',{staticClass:"d-flex",staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"6","md":"6","lg":"3","xl":"2"}},[_c('FormElement',{key:"ENTRY_NAME",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.name'),"rules":"required","topLabel":true,"labelShown":false,"cols":9,"lg":9,"xl":9,"disabled":true,"formType":{
                  name: 'text',
                },"placeholder":_vm.$t('labels.name')},model:{value:(_vm.item.ListName),callback:function ($$v) {_vm.$set(_vm.item, "ListName", $$v)},expression:"item.ListName"}}),_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"12px !important","height":"65%"},attrs:{"elevation":"0","color":"var(--mainColor)","dark":"","type":"button"},on:{"click":function($event){_vm.updateNameModal = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"6","md":"6","lg":"3","xl":"2"}},[_c('FormElement',{key:"ENTRY_EXPIRY_DATE",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.expiryDate'),"rules":"required","topLabel":true,"labelShown":false,"disabled":true,"cols":9,"lg":9,"xl":9,"formType":{
                  name: 'date',
                  dateMode: 'date',
                },"placeholder":_vm.$t('labels.expiryDate')},model:{value:(_vm.item.ExpiryDate),callback:function ($$v) {_vm.$set(_vm.item, "ExpiryDate", $$v)},expression:"item.ExpiryDate"}}),_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"12px !important","height":"65%"},attrs:{"elevation":"0","color":"var(--mainColor)","dark":"","type":"button"},on:{"click":function($event){_vm.updateExpiryDateModal = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('FormElement',{key:"ENTRY_COUNTRY",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.country'),"rules":"required","topLabel":true,"labelShown":false,"lg":2,"disabled":true,"formType":{
                name: 'text',
              },"placeholder":_vm.$t('labels.country')},model:{value:(_vm.priceData.Result.OriginCountry),callback:function ($$v) {_vm.$set(_vm.priceData.Result, "OriginCountry", $$v)},expression:"priceData.Result.OriginCountry"}}),_c('FormElement',{key:"ENTRY_CURRENCY",attrs:{"vertical":true,"validationLabel":_vm.$t('labels.currency'),"rules":"required","topLabel":true,"labelShown":false,"lg":2,"disabled":true,"formType":{
                name: 'text',
              },"placeholder":_vm.$t('labels.currency')},model:{value:(_vm.item.CurrencyCode),callback:function ($$v) {_vm.$set(_vm.item, "CurrencyCode", $$v)},expression:"item.CurrencyCode"}}),_c('FormElement',{key:"ENTRY_SHIPMENT_TYPE",attrs:{"vertical":true,"validationLabel":_vm.$t('titles.shipmentType'),"disabled":true,"topLabel":true,"labelShown":false,"lg":2,"formType":{
                name: 'select',
                items: {
                  data: _vm.shipmentTypes,
                  value: 'Id',
                  text: 'Name',
                },
              },"placeholder":_vm.$t('titles.shipmentType')},model:{value:(_vm.item.ShipmentType),callback:function ($$v) {_vm.$set(_vm.item, "ShipmentType", $$v)},expression:"item.ShipmentType"}})],1),_c('v-divider'),_c('div',{staticStyle:{"position":"relative","width":"100%"}},[(
                !(_vm.activeTab == 2 && !_vm.outOfAreaData) &&
                (_vm.$vuetify.breakpoint.xl ||
                  _vm.$vuetify.breakpoint.lg ||
                  _vm.$vuetify.breakpoint.md)
              )?_c('div',{staticClass:"d-flex mt-6 px-4",staticStyle:{"position":"absolute","right":"0","top":"0","z-index":"1"}},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","href":_vm.activeTab === 0
                    ? _vm.priceData?.Result?.PriceFileUrl
                    : _vm.priceData?.Result?.RegionFileUrl,"depressed":"","download":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(_vm._s(_vm.$t('buttons.downloadFile')))],1),_c('div',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","depressed":""},on:{"click":_vm.fileUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" "+_vm._s(_vm.$t('buttons.changeExcelFile'))+" ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.onFileChanged}})],1)],1):_vm._e(),(
                !(_vm.activeTab == 2 && !_vm.outOfAreaData) &&
                !(
                  _vm.$vuetify.breakpoint.xl ||
                  _vm.$vuetify.breakpoint.lg ||
                  _vm.$vuetify.breakpoint.md
                )
              )?_c('div',{staticClass:"d-flex mt-4 px-4",staticStyle:{"z-index":"1"}},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","href":_vm.activeTab === 0
                    ? _vm.priceData?.Result?.PriceFileUrl
                    : _vm.priceData?.Result?.RegionFileUrl,"depressed":"","download":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(_vm._s(_vm.$t('buttons.downloadFile')))],1),_c('div',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","depressed":""},on:{"click":_vm.fileUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" "+_vm._s(_vm.$t('buttons.changeExcelFile'))+" ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.onFileChanged}})],1)],1):_vm._e(),_c('TabScreen',{staticClass:"px-4 pt-4",attrs:{"slots":_vm.slots,"changeIndexFunction":_vm.onTabChange},scopedSlots:_vm._u([{key:"prices",fn:function(){return [_c('div',[(_vm.priceData)?_c('v-simple-table',{staticStyle:{"width":"100%","border-collapse":"collapse","border-radius":"0","border-style":"solid","border-bottom-width":"1px","border-left-width":"2px","border-right-width":"2px","border-top-width":"0px","border-color":"lightgray"},attrs:{"border":"2"}},[_c('thead',_vm._l((_vm.priceData.Result.Headers),function(item,index){return _c('th',[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('tbody',_vm._l((_vm.priceData.Result.Values),function(item,index){return _c('tr',_vm._l((item),function(value,valueIndex){return _c('td',{staticStyle:{"padding":"0 25px","text-align":"center"}},[_vm._v(" "+_vm._s(value ? value : '-')+" ")])}),0)}),0)]):_vm._e()],1)]},proxy:true},{key:"regions",fn:function(){return [_c('div',{staticStyle:{"border-radius":"0","border-style":"solid","border-bottom-width":"1px","border-left-width":"2px","border-right-width":"2px","border-top-width":"0px","border-color":"lightgray"}},[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('messages.noData'),"headers":[
                      {
                        text: _vm.$t('labels.country'),
                        value: 'CountryName',
                        sortable: true,
                        groupable: false,
                        align: 'center',
                      },
                      {
                        text: _vm.$t('labels.region'),
                        value: 'RegionName',
                        sortable: true,
                        groupable: false,
                        align: 'center',
                      },
                      {
                        text: _vm.$t('labels.minZipCode'),
                        value: 'MinZipCode',
                        sortable: true,
                        groupable: false,
                        align: 'center',
                      },
                      {
                        text: _vm.$t('labels.maxZipCode'),
                        value: 'MaxZipCode',
                        sortable: true,
                        groupable: false,
                        align: 'center',
                      },
                      {
                        text: _vm.$t('titles.transferTime'),
                        value: 'TransitTime',
                        sortable: true,
                        groupable: false,
                        align: 'center',
                      },
                    ],"loading":_vm.$store.state.loadingStatus,"options":{
                      itemsPerPage: _vm.itemsPerPage,
                      page: _vm.page,
                      sortBy: ['Region', 'DestinationCountry'],
                      sortDesc: [_vm.sortDesc],
                    },"hide-default-footer":"","items":_vm.priceData.Result.Regions,"item-key":'DestinationCountry'},on:{"update:sort-desc":_vm.sort},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center mt-8"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(
                              _vm.$vuetify.breakpoint.md ||
                              _vm.$vuetify.breakpoint.lg ||
                              _vm.$vuetify.breakpoint.xl
                            )?_c('v-col',{attrs:{"md":"2"}}):_vm._e(),_c('v-col',{attrs:{"cols":"9","sm":"12","md":"7","lg":"7","xl":"7"}},[_c('v-pagination',{staticClass:"my-4",attrs:{"value":_vm.page,"length":_vm.pageLength,"circle":"","total-visible":_vm.$vuetify.breakpoint.xs ? 10 : 10},on:{"input":_vm.onPageChange}})],1),_c('v-col',{staticClass:"ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6",attrs:{"cols":"11","sm":"11","md":"3","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"items":[5, 10, 15, 20, 50, 100, 1000],"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","hide-details":"","rounded":""},on:{"change":_vm.changeItemsPerPage},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10 d-flex flex-column align-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('messages.noSearch')))]),_c('v-btn',{staticClass:"button my-2",staticStyle:{"color":"white","background-color":"var(--darkBlue)"},on:{"click":function($event){_vm.showCreateOffer = true}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.search'))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-magnify ")])],1)],1)]},proxy:true}],null,true)})],1)]},proxy:true}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center"})],1)],1)]}}],null,false,3218841150)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }