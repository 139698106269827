// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'express-cargo';

export default {
  add(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  addOutOfArea(body) {
    return axios.post(`${RESOURCE_NAME}/add-out-area`, body);
  },
  updatePriceExcel(body) {
    return axios.put(`${RESOURCE_NAME}/update-priceExcel`, body);
  },
  updateRegionExcel(body) {
    return axios.put(`${RESOURCE_NAME}/update-regionExcel`, body);
  },
  updatePriceList(body) {
    return axios.put(`${RESOURCE_NAME}/update-priceList`, body);
  },
  updateOutOfArea(body) {
    return axios.put(`${RESOURCE_NAME}/update-out-area`, body);
  },
  updateTransportTime(body) {
    return axios.put(`${RESOURCE_NAME}/update-transport-time`, body);
  },
  updateExpiryDate(body) {
    return axios.put(`${RESOURCE_NAME}/update-expiry-date`, body);
  },
  updateFSC(body) {
    return axios.put(`${RESOURCE_NAME}/update-fsc`, body);
  },
  getPriceList(params) {
    return axios.get(`${RESOURCE_NAME}/get-priceList`, { params });
  },
  getOutOfArea() {
    return axios.get(`${RESOURCE_NAME}/get-out-area`, {});
  },
  getRegionExcel(id) {
    return axios.get(`${RESOURCE_NAME}/get-regionExcel?Id=${id}`);
  },
  getPriceExcel(id) {
    return axios.get(`${RESOURCE_NAME}/get-priceexcel?Id=${id}`);
  },
  getOutOfAreaExcel(id) {
    return axios.get(`${RESOURCE_NAME}/get-out-area?Id=${id}`);
  },
  updateName(body) {
    return axios.put(`${RESOURCE_NAME}/update-list-name`, body);
  },

  /**
   * Update optional Reference ID.
   * @param {*} body 
   * @returns {Promise}
   */
  updateOptionalReferenceID(body) {
    return axios.put(`${RESOURCE_NAME}/update-referansId`, body);
  },
};
