// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = 'Users';

export default {
  /**
   * Creates a user. It is not like register. You can take them to any company.
   * @param {*} body { Name, Surname, Username, Email, Password, Gender, PhoneNumber, CompanyId, Authority }
   * @returns {Promise<any>}
   */
  createUser(body) {
    return axios.post(`${RESOURCE_NAME}`, body);
  },

  /**
   * Updates a user role.
   * @param {*} body
   * @returns {Promise<any>}
   */
  updateUserRole(body) {
    return axios.post(`${RESOURCE_NAME}/assign-role-to-user`, body);
  },
  updatePasswordWithOld(body) {
    return axios.post(`${RESOURCE_NAME}/update-password-without-token`, body);
  },
  updateUserSigning(body) {
    return axios.put(`${RESOURCE_NAME}/update-signing`, body);
  },

  /**
   * Updates a user.
   * @param {*} body
   * @returns {Promise<any>}
   */
  updateUser(body) {
    return axios.put(`${RESOURCE_NAME}/update-user`, body);
  },

  /**
   * Send request for a new verification code.
   * @param {*} body
   * @returns {Promise<any>}
   */
  sendVerificationCode(body) {
    return axios.post(`${RESOURCE_NAME}/updatePhoneRequest`, body);
  },

  /**
   * Changes the user's phone number
   * @param {*} body
   * @returns {Promise<any>}
   */
  changePhoneNumber(body) {
    return axios.put(`${RESOURCE_NAME}/updatehoneRequest/confirm`, body);
  },

  /**
   * Get users you added by filter.
   * @param {integer} pageSize default = 1, Optional
   * @param {integer} page defaut = 1, Optional
   * @returns {Promise<any>}
   */
  getUsers(page = 1, pageSize = 15) {
    return axios.get(`${RESOURCE_NAME}`, {
      params: {
        Size: pageSize,
        Page: page,
      },
    });
  },
  /**
   * Get company users you added by filter.
   * @param {integer} pageSize default = 1, Optional
   * @param {integer} page defaut = 1, Optional
   * @returns {Promise<any>}
   */
  getCompanyUsers(params) {
    return axios.get(`${RESOURCE_NAME}/get-company-users`, {
      params
    });
  },

  /**
   * Get roles which one is belong to specific user.
   * @param {integer} userId Required
   * @returns {Promise<any>}
   */
  getUserRoles(userId) {
    return axios.get(`${RESOURCE_NAME}/get-roles-to-user`, {
      params: { UserId: userId },
    });
  },

  /**
   * Assign Role any user for company.
   * @param {*} body { UserId, Roles[] }, Required
   * @returns {Promise<any>}
   */
  assignRole(body) {
    return axios.post(`${RESOURCE_NAME}/assign-role-to-user`, body);
  },

  /**
   * Get detail information about user.
   * @param {*} id { UserId }, Required
   * @returns {Promise<any>}
   */
  getUserDetail(id) {
    return axios.get(`${RESOURCE_NAME}/getUserById/`, {
      params: { Id: id },
    });
  },

  /**
   * Get all approved users.
   * @param {*} params
   * @returns {Promise<any>}
   */
  getApprovedUsers(params) {
    return axios.get(`admin/get`, {
      params,
    });
  },

   /**
   * Approve or block an user.
   * @param {*} body
   * @returns {Promise<any>}
   */
   updateUserStatus(body) {
    return axios.put(`admin/update-approve-user`, body);
  },
};
