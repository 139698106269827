<template>
  <v-card elevation="1" class="mt-0 py-lg-4" style="margin: 0 !important">
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center d-flex align-center" cols="12">
          <div class="d-flex mb-2 mb-lg-0">
            <v-icon
              large
              class="mr-3"
              style="color: #0177d4 !important"
              id="menu-activator"
              @click="goBack"
            >
              mdi-arrow-left
            </v-icon>
            <h3>{{ item.ListName }}</h3>
          </div>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                style="font-size: 32px"
                v-bind="attrs"
                v-on="on"
                color="var(--primaryColor)"
                @click="
                  form = Object.assign({}, item);
                  updatePriceListModal = true;
                "
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('buttons.update') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />

    <v-dialog v-model="updatePriceListModal" max-width="400">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.update') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver
          ref="updatePriceListObserver"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(update)">
            <v-card-text>
              <FormElement
                v-for="(element, index) in formItems"
                :key="
                  element.key.parent + '_' + element.key.child + '_' + index
                "
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :cols="element.cols"
                :sm="element.sm"
                :md="element.md"
                :lg="element.lg"
                :light="false"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :disabled="element.disabled"
                :placeholder="$t(element.placeholder)"
                :classes="element.classes"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="saveLoading"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="updatePriceListModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <ConfirmationModal
      v-model="confirmationModal"
      :title="$t('labels.warning')"
      :message="$t('messages.changeTheExcelFile')"
      :confirm="finishExcelFileOperation"
    />
    <v-card v-if="priceData && regionData" class="mt-5">
      <v-card-text style="padding: 0px; margin: 0px">
        <v-row class="px-4 pt-4">
          <FormElement
            key="ENTRY_REFERENCE_ID_2"
            :vertical="true"
            v-model="item.OptionalReferansId"
            :validationLabel="$t('labels.optionalReferenceID')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            disabled
            :placeholder="$t('labels.optionalReferenceID')"
          />
          <FormElement
            key="ENTRY_ID"
            :vertical="true"
            v-model="item.ReferanceId"
            :validationLabel="$t('labels.referenceId')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.referenceId')"
          />
          <FormElement
            key="ENTRY_NAME"
            :vertical="true"
            v-model="item.ListName"
            :validationLabel="$t('labels.customerList')"
            rules="required"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.customerList')"
          />

          <FormElement
            key="ENTRY_CARGO"
            :vertical="true"
            v-model="item.CargoName"
            :validationLabel="$t('labels.priceListName')"
            rules="required"
            :disabled="true"
            :topLabel="true"
            :labelShown="false"
            :active="isSupplier"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.priceListName')"
          />

          <FormElement
            key="ENTRY_COUNTRY"
            :vertical="true"
            v-model="regionData.Result.OriginCountry"
            :validationLabel="$t('labels.country')"
            rules="required"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.country')"
          />

          <FormElement
            key="ENTRY_PORTAL_TYPE"
            :vertical="true"
            v-model="item.IntegrationLogistic"
            :validationLabel="$t('titles.subsupplier')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('titles.subsupplier')"
          />
          <FormElement
            key="ENTRY_INTEGRATION_SERVICE"
            :vertical="true"
            v-model="item.IntegrationService"
            :validationLabel="$t('titles.integrationService')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('titles.integrationService')"
          />
          <FormElement
            key="ENTRY_PORTAL_USERS"
            :vertical="true"
            v-model="item.PortalCompaniesString"
            :validationLabel="$t('labels.portalUsers')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.portalUsers')"
          />
          <FormElement
            key="ENTRY_EXPIRY_DATE"
            :vertical="true"
            v-model="item.ExpiryDate"
            :validationLabel="$t('labels.expiryDate')"
            rules="required"
            :topLabel="true"
            :labelShown="false"
            :disabled="true"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :formType="{
              name: 'date',
              dateMode: 'date',
            }"
            :placeholder="$t('labels.expiryDate')"
          />

          <FormElement
            key="FSC_PERCENTAGE"
            :vertical="true"
            v-model="item.FscRate"
            rules="required|numeric|min_value:0|max_value:100"
            validationLabel="FSC"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="3"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            placeholder="FSC"
          />
          <FormElement
            key="ACCOUNTS"
            :vertical="true"
            v-model="item.IntegrationLogisticAccount"
            :validationLabel="$t('labels.accounts')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="4"
            :lg="2"
            :xl="2"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.accounts')"
          />

          <FormElement
            key="MAX_KG"
            :vertical="true"
            v-model="item.ServiceMaxKg"
            rules="required|numeric|min_value:0|max_value:100"
            :validationLabel="$t('labels.maxKg')"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="2"
            :lg="2"
            :xl="1"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.maxKg')"
          />
          <FormElement
            key="ENTRY_CURRENCY"
            :vertical="true"
            v-model="item.CurrencyCode"
            :validationLabel="$t('labels.currency')"
            rules="required"
            :topLabel="true"
            :labelShown="false"
            :cols="12"
            :sm="6"
            :md="2"
            :lg="2"
            :xl="1"
            :disabled="true"
            :formType="{
              name: 'text',
            }"
            :placeholder="$t('labels.currency')"
          />
        </v-row>

        <v-divider />
        <div style="position: relative; width: 100%">
          <div
            v-if="
              !(activeTab == 2) &&
              ($vuetify.breakpoint.xl ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.md) &&
              isSupplier
            "
            class="d-flex mt-6 px-4"
            style="position: absolute; right: 0; top: 0; z-index: 1"
          >
            <v-btn
              style="border-radius: 12px !important"
              elevation="0"
              color="primary"
              class="button"
              dark
              :href="
                activeTab === 0
                  ? priceData?.Result?.FileUrl
                  : activeTab === 1
                  ? regionData?.Result?.FileUrl
                  : null
              "
              depressed
              download
              ><v-icon left> mdi-download </v-icon
              >{{ $t('buttons.downloadFile') }}</v-btn
            >
            <div class="ml-2">
              <v-btn
                style="border-radius: 12px !important"
                elevation="0"
                color="primary"
                class="button"
                dark
                depressed
                @click="onButtonClick"
              >
                <v-icon left> mdi-upload </v-icon>
                {{ $t('buttons.changeExcelFile') }}
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="onFileChanged"
              />
            </div>
          </div>

          <div
            v-if="
              !(activeTab == 2) &&
              !(
                $vuetify.breakpoint.xl ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.md
              )
            "
            class="d-flex mt-4 px-4"
            style="z-index: 1"
          >
            <v-btn
              style="border-radius: 12px !important"
              elevation="0"
              color="primary"
              class="button"
              dark
              :href="
                activeTab === 0
                  ? priceData?.Result?.FileUrl
                  : activeTab === 1
                  ? regionData?.Result?.FileUrl
                  : null
              "
              depressed
              download
              ><v-icon left> mdi-download </v-icon
              >{{ $t('buttons.downloadFile') }}</v-btn
            >
            <div class="ml-2">
              <v-btn
                style="border-radius: 12px !important"
                elevation="0"
                color="primary"
                class="button"
                dark
                depressed
                @click="onButtonClick"
              >
                <v-icon left> mdi-upload </v-icon>
                {{ $t('buttons.changeExcelFile') }}
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="onFileChanged"
              />
            </div>
          </div>

          <TabScreen
            :slots="slots"
            :changeIndexFunction="onTabChange"
            class="px-4 pt-4"
          >
            <template v-slot:prices>
              <div>
                <v-simple-table
                  v-if="priceData"
                  class="mb-4"
                  style="
                    border-collapse: collapse;
                    border-radius: 0;
                    border-style: solid;
                    border-bottom-width: 1px;
                    border-left-width: 2px;
                    border-right-width: 2px;
                    border-top-width: 0px;
                    border-color: lightgray;
                  "
                >
                  <thead
                    style="
                      border-style: solid;
                      border-top-width: 1px;
                      border-color: lightgray;
                    "
                  >
                    <tr>
                      <th class="text-center">Ağırlık/Bölge</th>
                      <th
                        v-for="(item, index) in priceData.Result.Headers"
                        :key="index"
                        class="text-center"
                      >
                        {{ item }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in priceData.Result.TableData"
                      :key="index"
                    >
                      <td
                        v-for="(value, valueIndex) in item"
                        :key="valueIndex"
                        class="text-center"
                      >
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </template>
            <template v-slot:regions>
              <div
                style="
                  border-radius: 0;
                  border-style: solid;
                  border-bottom-width: 1px;
                  border-left-width: 2px;
                  border-right-width: 2px;
                  border-top-width: 0px;
                  border-color: lightgray;
                "
              >
                <v-data-table
                  :no-data-text="$t('messages.noData')"
                  @update:sort-desc="sort"
                  :headers="[
                    {
                      text: $t('labels.region'),
                      value: 'Region',
                      sortable: true,
                      groupable: false,
                      align: 'center',
                    },
                    {
                      text: $t('labels.country'),
                      value: 'DestinationCountry',
                      sortable: true,
                      groupable: false,
                      align: 'center',
                    },
                    {
                      text: $t('titles.transferTime'),
                      value: 'TransportTime',
                      sortable: true,
                      groupable: false,
                      align: 'center',
                    },
                  ]"
                  :loading="$store.state.loadingStatus"
                  :options="{
                    itemsPerPage: itemsPerPage,
                    page: page,
                    sortBy: ['Region', 'DestinationCountry'],
                    sortDesc: [sortDesc],
                  }"
                  hide-default-footer
                  :items="regionData.Result.ExpressCargoRegions"
                  :item-key="'DestinationCountry'"
                >
                  <template v-slot:footer>
                    <div class="d-flex justify-center mt-8">
                      <v-row align="center" justify="center">
                        <v-col
                          v-if="
                            $vuetify.breakpoint.md ||
                            $vuetify.breakpoint.lg ||
                            $vuetify.breakpoint.xl
                          "
                          md="2"
                        ></v-col>
                        <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                          <v-pagination
                            class="my-4"
                            @input="onPageChange"
                            :value="page"
                            :length="pageLength"
                            circle
                            :total-visible="$vuetify.breakpoint.xs ? 10 : 10"
                          ></v-pagination>
                        </v-col>
                        <v-col
                          cols="11"
                          sm="11"
                          md="3"
                          lg="3"
                          xl="3"
                          class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                        >
                          <v-select
                            v-model="itemsPerPage"
                            :items="[5, 10, 15, 20, 50, 100, 1000]"
                            :label="$t('labels.selectItemsPerPage')"
                            item-value="value"
                            item-text="name"
                            outlined
                            dense
                            hide-details
                            @change="changeItemsPerPage"
                            rounded
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <div class="my-10 d-flex flex-column align-center">
                      <h4>{{ $t('messages.noSearch') }}</h4>
                      <v-btn
                        style="color: white; background-color: var(--darkBlue)"
                        class="button my-2"
                        @click="showCreateOffer = true"
                      >
                        {{ $t('buttons.search') }}
                        <v-icon dark right> mdi-magnify </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </template>
          </TabScreen>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> </v-card-actions>
    </v-card>
  </v-card>
</template>
<script>
import ExpressCargoServices from '../services/ExpressCargoServices';
import { ITEMS_PER_PAGE_OPTIONS } from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import TabScreen from '../components/TabScreen.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import PortalService from '../services/PortalService';
import IntegrationService from '../services/IntegrationService';

export default {
  data() {
    return {
      item: {
        isActive: false,
      },
      priceData: null,
      regionData: null,
      slots: [],
      isSelecting: false,
      file: null,
      selectedFiles: {
        priceExcel: null,
        regionExcel: null,
      },
      activeTab: 0,
      page: 1,
      itemsPerPage: 10,

      confirmationModal: false,
      sortDesc: null,

      form: {},
      saveLoading: false,

      isSupplier: false,
      portalUsers: [],

      updatePriceListModal: false,
    };
  },
  components: { FormElement, TabScreen, ConfirmationModal },
  mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.item = this.$route.params.item;
    console.log(this.item);
    if (!this.item) this.$router.push('price-chart-entry');
    else {
      this.getPortalUsers();
      this.getAccounts(this.item.IntegrationLogistic);

      this.item.ExpiryDate = this.item.ExpiryDate.slice(0, 10);
      this.item.isActive = !this.item.PriceListStatus;
      this.item.PortalCompaniesString = this.item.PortalCompanies.join(',');

      this.form = {
        ExpiryDate: this.item.ExpiryDate,
        FscRate: this.item.FscRate,
        ListName: this.item.ListName,
        PortalCompanyIds: this.item.PortalCompanyIds,
        CargoName: this.item.CargoName,
      };

      this.slots = [
        {
          name: 'prices',
          title: this.$t('labels.priceList'),
          unSeenCount: 0,
        },
        {
          name: 'regions',
          title: this.$t('labels.regionList'),
          unSeenCount: 0,
        },
      ];

      this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
      this.setup();
    }
  },
  watch: {},
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'isActive',
          },
          active: this.item.PriceListStatus !== 2,
          validationLabel: 'labels.isActive',
          formType: {
            name: 'checkbox',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.isActive',
          classes: 'mb-4',
        },
        {
          key: {
            parent: 'form',
            child: 'OptionalReferansId',
          },
          validationLabel: 'labels.optionalReferenceID',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.optionalReferenceID',
        },
        {
          key: {
            parent: 'form',
            child: 'ListName',
          },
          validationLabel: 'labels.customerList',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.customerList',
        },
        {
          key: {
            parent: 'form',
            child: 'CargoName',
          },
          validationLabel: 'labels.priceListName',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.priceListName',
        },
        {
          key: {
            parent: 'form',
            child: 'ExpiryDate',
          },
          validationLabel: 'labels.expiryDate',
          formType: {
            name: 'date',
            dateMode: 'date',
          },
          lg: 12,
          labelShown: false,
          disabled: !this.isSupplier,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.expiryDate',
        },
        {
          key: {
            parent: 'form',
            child: 'FscRate',
          },
          validationLabel: 'labels.fsc',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.fsc',
        },

        {
          key: {
            parent: 'form',
            child: 'IntegrationLogisticId',
          },
          validationLabel: 'labels.accounts',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.accounts,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.accounts',
        },

        {
          key: {
            parent: 'form',
            child: 'PortalCompanyIds',
          },
          validationLabel: 'labels.portalUsers',
          formType: {
            name: 'multipleSelect',
            multiple: true,
            items: {
              data: this.portalUsers,
              value: 'Id',
              text: 'CompanyName',
            },
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          disabled: !this.isSupplier,
          placeholder: 'labels.portalUsers',
        },
      ];
    },
    pageLength() {
      return Math.ceil(
        this.regionData.Result.ExpressCargoRegions.length / this.itemsPerPage
      );
    },
  },
  methods: {
    sort(item) {
      this.sortDesc = !!item;
    },
    goBack() {
      if (this.$router.getRoutes().length === 0) {
        this.$router.push({ name: 'PriceChartEntry' });
      } else {
        this.$router.go(-1);
      }
    },
    onTabChange(index) {
      this.activeTab = index;
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.confirmationModal = true;
    },
    finishExcelFileOperation() {
      let service = null,
        formData = new FormData(),
        finalService = null;
      if (this.activeTab === 0) {
        service = ExpressCargoServices.updatePriceExcel;
        formData.append('PriceExcelId', this.item.PriceExcelId);
        finalService = this.getPriceExcel;
        this.selectedFiles.priceExcel = this.file;
      } else if (this.activeTab === 1) {
        service = ExpressCargoServices.updateRegionExcel;
        formData.append('RegionExcelId', this.item.RegionExcelId);
        formData.append(
          'OriginCountryId',
          this.regionData.Result.OriginCountryId
        );
        finalService = this.getRegionExcel;
        this.selectedFiles.regionExcel = this.file;
      }
      formData.append('File', this.file);

      if (service) {
        this.$store.state.loadingStatus = true;
        service(formData)
          .then((response) => {
            window.showSuccess(this.$t('messages.changeFileSuccessful'));
            finalService();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    update() {
      this.$store.state.loadingStatus = true;
      this.saveLoading = true;
      let requestFunction = null,
        body = {
          RegionPriceListId: this.item.Id,
          isActive: this.form.isActive,
          OptionalReferansId: this.form.OptionalReferansId,
          ListName: this.form.ListName,
          ExpiryDate: this.form.ExpiryDate,
          FscRate: this.form.FscRate,
          PortalCompanyIds: this.form.PortalCompanyIds,
          CargoName: this.form.CargoName,
          IntegrationLogisticId: this.form.IntegrationLogisticId,
        };
      requestFunction = ExpressCargoServices.updatePriceList;

      requestFunction(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateActiveExpress'));
          const data = response.data.Result;

          this.form.PortalCompanies = data.PortalCompanyNames.split(',');
          this.form.PortalCompanyIds = data.PortalCompanyIds;
          this.form.PortalCompaniesString = data.PortalCompanyNames;

          this.item = Object.assign({}, this.form);
          this.updatePriceListModal = false;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.saveLoading = false;
        });
    },

    getPortalUsers() {
      this.$store.state.loadingStatus = true;
      PortalService.getPortalUsers(null, null, null, null, 1, 999999999)
        .then((response) => {
          this.portalUsers = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getAccounts(logisticId) {
      this.$store.state.loadingStatus = true;
      IntegrationService.getAccounts(logisticId)
        .then((response) => {
          this.accounts = response.data.Result;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage(newValue) {
      this.page = 1;
      this.itemsPerPage = newValue;
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
    },

    async setup() {
      // Getting Excel
      this.getRegionExcel();
      this.getPriceExcel();
    },

    getRegionExcel() {
      ExpressCargoServices.getRegionExcel(this.item.RegionExcelId)
        .then((response) => {
          this.regionData = response?.data;
        })
        .catch(window.showError)
        .finally(() => {});
    },
    getPriceExcel() {
      ExpressCargoServices.getPriceExcel(this.item.PriceExcelId)
        .then((response) => {
          this.priceData = response?.data;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}

.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
