<template>
  <v-navigation-drawer
    permanent
    absolute
    color="#1a305c"
    style="padding-top: 60px; max-width: 240px"
  >
    <v-list nav dense expand>
      <v-list-item-group>
        <v-list-item
          v-if="$store.getters.isAuthenticated"
          @click="navigateHome()"
          style="background-color: #314a7e !important"
        >
          <v-list-item-avatar>
            <v-img :src="item.PhotoUrl"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              style="font-weight: bold !important; color: #ffffff"
              >{{ item.fullName }}</v-list-item-title
            >
            <v-list-item-subtitle style="color: #ffffff !important"
              >{{ item.CompanyName
              }}<span style="font-weight: bold" v-if="pendingStatus === 0"
                >({{ $t('labels.waitingForApproval') }})</span
              ></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-layout
          v-if="item.roles.includes($store.state.Type)"
          v-for="(item, index) in menuItems"
          :key="index"
        >
          <v-list-item
            @click="$emit('close-drawer')"
            :to="'/home/' + item.to"
            v-if="item.children.length < 1"
            class="py-1"
            :class="menuItems.length - 1 !== index && 'border-white'"
            style="border-radius: 0% !important"
            link
          >
            <v-list-item-icon>
              <v-icon
                color="white"
                class="navItemIcon"
                style="font-size: 28px"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title
              :class="item.classes ? item.classes.join(' ') : ''"
              style="font-size: 14px; color: #ffffff"
              >{{
                $store.state.Type === 'Admin' && item.id == 'orders'
                  ? $t('navigationDrawerMenu.ordersAdmin')
                  : item.title
                  ? item.title
                  : $t('navigationDrawerMenu.' + item.id)
              }}</v-list-item-title
            >
          </v-list-item>

          <v-list-group
            v-model="menu[item.id]"
            color="white"
            :append-icon="null"
            no-action
            v-if="item.children.length > 0"
            style="width: 100%; border-bottom: inset white !important"
          >
            <v-icon
              slot="prependIcon"
              style="font-size: 28px; color: #ffffff !important"
              >{{ item.icon }}</v-icon
            >
            <template v-slot:activator>
              <v-list-item-title
                class="ml-1"
                style="font-size: 14px; color: #ffffff !important"
                >{{
                  item.title
                    ? item.title
                    : $t('navigationDrawerMenu.' + item.id)
                }}</v-list-item-title
              >
              <v-list-item-icon>
                <v-icon color="#fcd06e">{{
                  menu[item.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-list-item-icon>
            </template>
            <v-layout
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
            >
              <v-list-item
                v-if="child.roles.includes($store.state.Type) && child.children.length < 1"
                style="padding-left: 12%; border-radius: 0% !important"
                :class="
                  item.children.length - 1 !== childIndex && 'border-white'
                "
                link
                :to="'/home/' + child.to"
                :key="childIndex"
              >
                <v-list-item-icon
                  style="
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                  "
                >
                  <v-icon style="color: #ffffff !important">{{
                    child.icon
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  style="font-size: 14px; color: #ffffff !important"
                  >{{
                    child.title
                      ? child.title
                      : $t('navigationDrawerMenu.' + child.id)
                  }}</v-list-item-title
                >
              </v-list-item>
              <v-list-group
                no-action
                class="v-list-group__subheader"
                :value="false"
                v-if="child.children.length > 0"
                style="width: 100%; border-bottom: inset white !important"
              >
                <v-icon slot="prependIcon" style="color: #ffffff !important">{{
                  child.icon
                }}</v-icon>

                <template v-slot:activator>
                  <v-list-item-title
                    style="font-size: 14px; color: #ffffff !important"
                    >{{
                      child.title
                        ? child.title
                        : $t('navigationDrawerMenu.' + child.id)
                    }}</v-list-item-title
                  >
                </template>
                <v-list-item
                  v-for="(lastChild, lastChildIndex) in child.children"
                  style="padding-left: 12%; border-radius: 0% !important"
                  :class="
                    item.children.length - 1 !== childIndex && 'border-white'
                  "
                  :to="'/home/' + lastChild.to"
                  :key="lastChildIndex"
                >
                  <v-list-item-icon
                    style="
                      margin-top: auto;
                      margin-bottom: auto;
                      margin-right: 10px;
                    "
                  >
                    <v-icon style="color: #ffffff !important">{{
                      lastChild.icon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    style="font-size: 14px; color: #ffffff !important"
                    >{{
                      lastChild.title
                        ? lastChild.title
                        : $t('navigationDrawerMenu.' + lastChild.id)
                    }}</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>
            </v-layout>
          </v-list-group>
        </v-layout>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapActions } from 'vuex';
import UserService from '../services/UserService';

export default {
  name: 'NavigationDrawer',
  props: {
    drawerOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pendingStatus: false,
      menuItems: [],
      item: {},
      menu: {
        pricing: true,
        integrationManagement: true,
        management: true,
        other: true,
        admin: true,
      },
    };
  },
  async mounted() {
    if (
      (this.$store.getters.getUserType == 'Customer' ||
        this.$store.getters.getUserType == 'PendingCustomer' ||
        this.$store.getters.getUserType == 'PendingSupplier' ||
        this.$store.getters.getUserType == 'Supplier') &&
      this.$store.state.Token
    )
      await this.getUnseenCounts();
    this.menuItems = [
      {
        id: 'home',
        icon: 'mdi-home-modern',
        to: 'dashboard',
        children: [],
        roles: [
          'Supplier',
          'Customer',
          'Unassigned',
          'Admin',
          'PendingCustomer',
          'PendingSupplier',
          'Planner',
          'Accountant',
          'Operation',
        ],
      },
      {
        id: 'companies',
        icon: 'mdi-domain',
        to: 'companies',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'pricing',
        icon: 'mdi-package-variant-closed',
        roles: ['Supplier'],
        to: 'price-chart-entry',
        children: [
          {
            id: 'priceChartEntry',
            icon: 'mdi-package-variant-closed',
            roles: ['Supplier'],
            to: 'price-chart-entry',
            children: [],
          },
          {
            id: 'partialTransportation',
            icon: 'mdi-truck-fast-outline',
            roles: ['Supplier'],
            to: 'partial-transportation',
            children: [],
          },
          {
            id: 'outOfAreaEntry',
            icon: 'mdi-texture-box',
            roles: ['Supplier'],
            to: 'out-of-area-entry',
            children: [],
          },
        ],
      },
      {
        id: 'quickSearch',
        icon: 'mdi-magnify',
        to: 'quick-search',
        children: [],
        roles: ['Customer', 'PendingCustomer'],
      },

      {
        id: 'searchHistory',
        icon: 'mdi-history',
        to: 'search-history',
        children: [],
        roles: ['Customer', 'PendingCustomer'],
      },
      {
        id: 'offers',
        icon: 'mdi-store-cog',
        children: [
          {
            id: 'spotOffers',
            title: this.spotOfferCount.title,
            icon: 'mdi-offer',
            to: 'spot-offers',
            classes: [this.spotOfferCount.class],
            children: [],
            roles: ['Customer', 'PendingCustomer'],
          },
          {
            id: 'listOffers',
            icon: 'mdi-package-variant-closed',
            roles: ['Customer'],
            to: 'price-chart-entry',
            children: [],
          },
        ],
        roles: ['Customer'],
      },
      {
        id: 'spotOffersSupplier',
        icon: 'mdi-offer',
        to: 'spot-price-requests-supplier',
        children: [],
        roles: ['Supplier', 'PendingSupplier'],
      },
      {
        id: 'orders',
        icon: 'mdi-cart-plus',
        to: 'orders',
        children: [],
        roles: [
          'Supplier',
          'Customer',
          'PendingCustomer',
          'PendingSupplier',
          'Operation',
          'Admin',
        ],
      },
      {
        id: 'currencyManagement',
        icon: 'mdi-currency-usd',
        to: 'currency-management',
        children: [],
        roles: ['Supplier'],
      },
      {
        id: 'plannerManagement',
        icon: 'mdi-note-outline',
        to: 'planner-management',
        children: [],
        roles: ['Supplier', 'Planner', 'Operation'],
      },
      {
        id: 'warehouse',
        icon: 'mdi-warehouse',
        to: 'warehouse',
        children: [],
        roles: ['Supplier', 'Warehouse'],
      },
      {
        id: 'accounting',
        icon: 'mdi-calculator',
        to: 'accounting',
        children: [],
        roles: ['Supplier', 'Accountant', 'Operation'],
      },
      {
        id: 'payments',
        icon: 'mdi-cash-clock',
        to: 'payments',
        children: [],
        roles: ['Customer', 'Supplier', 'PendingCustomer', 'PendingSupplier'],
      },
      {
        id: 'dynamicReports',
        icon: 'mdi-chart-multiple',
        to: 'dynamic-reports',
        children: [],
        roles: ['Supplier', 'PendingSupplier', 'Customer', 'PendingCustomer'],
      },
      {
        id: 'reports',
        icon: 'mdi-chart-line',
        to: 'reports',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'integrationManagement',
        icon: 'mdi-store-cog',
        children: [
          {
            id: 'marketplaceOrders',
            icon: 'mdi-shopping',
            to: 'marketplace-orders',
            children: [],
            roles: ['Customer'],
          },
          {
            id: 'marketplace',
            icon: 'mdi-store',
            to: 'marketplace',
            children: [],
            roles: ['Customer'],
          },
        ],
        roles: ['Customer'],
      },
      {
        id: 'management',
        icon: 'mdi-account-filter',
        roles: ['Supplier'],
        children: [
          {
            id: 'integrationManagement',
            icon: 'mdi-robot',
            to: 'integration-management',
            children: [],
            roles: ['Supplier'],
          },
          {
            id: 'portalManagement',
            icon: 'mdi-account-child',
            to: 'portal-management',
            children: [],
            roles: ['Supplier'],
          },
        ],
      },
      {
        id: 'messages',
        icon: 'mdi-message-processing-outline',
        to: 'messages',
        children: [],
        roles: ['Supplier', 'Customer', 'PendingCustomer', 'PendingSupplier'],
      },

      {
        id: 'additionalCosts',
        icon: 'mdi-cash-multiple',
        to: 'additional-costs',
        children: [],
        roles: ['Supplier', 'Accountant'],
      },
      {
        id: 'company',
        icon: 'mdi-office-building-cog',
        to: 'company-information',
        children: [],
        roles: [
          'Supplier',
          'Customer',
          'PendingCustomer',
          'PendingSupplier',
          'Planner',
          'Accountant',
          'Operation',
          'Warehouse',
        ],
      },
      {
        id: 'address',
        icon: 'mdi-map-marker',
        to: 'address',
        children: [],
        roles: ['Customer', 'PendingCustomer'],
      },
      {
        id: 'publicApi',
        icon: 'mdi-api',
        to: 'public-api',
        children: [],
        roles: ['Customer'],
      },
      {
        id: 'profile',
        icon: 'mdi-tune',
        to: 'profile',
        children: [],
        roles: ['Supplier', 'Customer', 'Planner'],
      },

      // {
      //   id: 'help',
      //   icon: 'mdi-help-circle-outline',
      //   to: 'help',
      //   children: [],
      //   roles: ['Supplier', 'Customer'],
      // },
      {
        id: 'addCompany',
        icon: 'mdi-file-document',
        to: 'addCompany',
        children: [],
        roles: ['Unassigned'],
      },

      {
        id: 'timeOut',
        icon: 'mdi-history',
        to: 'timeOut',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'blacklist',
        icon: 'mdi-account-cancel',
        to: 'blacklist',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'approvePayment',
        icon: 'mdi-check-decagram',
        to: 'approvePayment',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'adminPackageManagment',
        icon: 'mdi-package-variant',
        to: 'admin-package-managment',
        children: [],
        roles: ['Admin'],
      },
      {
        id: 'other',
        icon: 'mdi-table-settings',
        children: [
          {
            id: 'support',
            icon: 'mdi-lifebuoy',
            to: 'support',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Admin',
              'Unassigned',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
          {
            id: 'library',
            icon: 'mdi-library',
            to: 'library',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Admin',
              'Unassigned',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
          {
            id: 'userGuide',
            icon: 'mdi-help-network-outline',
            to: 'userGuide',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Planner',
              'Accountant',
              'Operation',
            ],
          },
          {
            id: 'FAQ',
            icon: 'mdi-comment-question-outline',
            to: 'faq',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Admin',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
          {
            id: 'dictionary',
            icon: 'mdi-book-alphabet',
            to: 'dictionary',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Admin',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
          {
            id: 'settings',
            icon: 'mdi-cogs',
            to: 'settings',
            children: [],
            roles: [
              'Supplier',
              'Customer',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        ],
        roles: [
          'Supplier',
          'Customer',
          'Planner',
          'Admin',
          'Accountant',
          'Operation',
        ],
      },
      // {
      //   id: 'admin',
      //   icon: 'mdi-menu',
      //   roles: ['Admin'],
      //   children: [
      //     /*{
      //       id: 'users',
      //       icon: 'mdi-account-group',
      //       to: 'users',
      //       children: [],
      //     },*/
      //     {
      //       id: 'companies',
      //       icon: 'mdi-domain',
      //       to: 'companies',
      //       children: [],
      //     },
      //     {
      //       id: 'timeOut',
      //       icon: 'mdi-history',
      //       to: 'timeOut',
      //       children: [],
      //     },
      //     {
      //       id: 'blacklist',
      //       icon: 'mdi-account-cancel',
      //       to: 'blacklist',
      //       children: [],
      //     },
      //     {
      //       id: 'approvePayment',
      //       icon: 'mdi-check-decagram',
      //       to: 'approvePayment',
      //       children: [],
      //     },
      //   ],
      // },
    ];
    this.setup();
    this.getMenuData();
  },
  computed: {
    spotOfferCount() {
      const count = this.$store.state.unseenCounts.NewOfferCount;
      let returnObject = {
        title: this.$t('navigationDrawerMenu.spotOffersCustomer'),
        class: '',
      };

      if (count && count > 0) {
        returnObject = {
          title: `${returnObject.title} (${count})`,
          class: 'bold-cell',
        };
      }

      return returnObject;
    },
  },
  components: {},

  methods: {
    getFullName(item) {
      if (!!item) {
        return item.Name + ' ' + item.Surname;
      } else {
        return '.';
      }
    },
    setup() {
      if (!this.$store.getters.isAuthenticated) {
        this.menuItems = [];
        return;
      }
      UserService.getUserDetail(this.$store.getters.getId)
        .then((res) => {
          if (res.data.Result.PendingStatus == 2) {
            this.logout();
          }

          this.pendingStatus = res.data.Result.PendingStatus;
          this.$store.state.CompanyName = res.data.Result.CompanyName;
          this.$store.state.PhoneNumber = res.data.Result.PhoneNumber;
          this.$store.state.Email = res.data.Result.Email;
          this.$store.state.RequestCount = res.data.Result.RequestCount || 0;

          let fullName = this.getFullName(res.data.Result);
          let roleName = res.data.Result.Roles[0]
            ? res.data.Result.Roles[0]
            : 'Atanmadı';

          this.$store.state.Type = roleName;

          this.item = res.data.Result;

          if (fullName) this.item.fullName = fullName;
          if (roleName) this.item.roleName = roleName;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },

    navigateHome() {
      this.$router.push({ name: 'profile' });
    },
    ...mapActions(['getMenuData', 'getUnseenCounts', 'logout']),
  },
};
</script>

<style>
.v-list-group__header {
  margin-bottom: 0px !important;
  margin-top: 0 !important;
  padding-left: 5px !important;
}
.v-list-group__subheader {
  margin-bottom: 0px !important;
  margin-top: 0 !important;
  padding-left: 11% !important;
}
.border-white {
  border-bottom: inset #314a7e !important;
  border-bottom-width: thin !important;
}
.navItemIcon {
  color: #ffffff;
}
</style>
