var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.changePasswordModal),callback:function ($$v) {_vm.changePasswordModal=$$v},expression:"changePasswordModal"}},[_c('ChangePassword'),(false)?_c('v-card',[_c('v-card-title',{staticClass:"text-h4 text-center justify-center mb-5"},[_vm._v(" "+_vm._s(_vm.$t('labels.changePassword'))+" ")]),(false)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('v-card-text',{staticClass:"font-weight-bold pl-8 body-1",staticStyle:{"line-height":"1.8","color":"#7443a0"}},[_c('v-text-field',{attrs:{"label":_vm.$t('labels.currentPassword'),"rules":_vm.exampleRules,"append-icon":_vm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showCurrentPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showCurrentPassword = !_vm.showCurrentPassword}},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('v-text-field',{ref:"newPassword",attrs:{"label":_vm.$t('labels.newPassword'),"rules":[
                _vm.newPassword != '' || 'Field required',
                _vm.newPassword.length >= 8 ||
                  'Bu alan en az 8 karakter olmalıdır',
                _vm.newPassword.length <= 16 ||
                  'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                _vm.reNewPassword == _vm.newPassword ||
                  'Şifre yeni şifre ile eşleşmiyor',
              ],"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"label":_vm.$t('labels.reNewPassword'),"rules":[
                _vm.reNewPassword != '' || 'Bu alan zorunludur',
                _vm.reNewPassword.length >= 8 ||
                  'Bu alan en az 8 karakter olmalıdır',
                _vm.reNewPassword.length <= 16 ||
                  'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                _vm.reNewPassword == _vm.newPassword ||
                  'Şifre yeni şifre ile eşleşmiyor',
              ],"append-icon":_vm.showReNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showReNewPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showReNewPassword = !_vm.showReNewPassword}},model:{value:(_vm.reNewPassword),callback:function ($$v) {_vm.reNewPassword=$$v},expression:"reNewPassword"}})],1),_c('v-card-actions',{staticStyle:{"background-color":"white","position":"sticky","bottom":"0px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.updateButtonControl,"color":"deep-purple lighten-2","text":""},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),(!_vm.changePasswordFlag)?_c('v-btn',{attrs:{"color":"deep-purple lighten-2","text":""},on:{"click":function($event){_vm.changePasswordModal = false;
                _vm.clearModal();}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")]):_vm._e()],1)],1)]}}],null,false,3151903961)}):_vm._e()],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialogLogout),callback:function ($$v) {_vm.dialogLogout=$$v},expression:"dialogLogout"}},[_c('v-card',{staticClass:"popup-card",attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"popupHeadline d-flex justify-center",staticStyle:{"font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.$t('title.logout'))+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"200px","max-height":"200px"},attrs:{"src":require("../images/error.png"),"alt":"Image Description"}}),_c('p',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t('description.logout'))+" ")])]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"rounded-lg",staticStyle:{"color":"white","text-transform":"none","font-size":"large","font-weight":"bold","padding":"1em"},attrs:{"variant":"tonal","color":"var(--red)"},on:{"click":_vm.LogOut}},[_vm._v(" "+_vm._s(_vm.$t('buttons.yes'))+" ")]),_c('v-btn',{staticClass:"rounded-lg",staticStyle:{"color":"white","text-transform":"none","font-size":"large","font-weight":"bold","padding":"1em"},attrs:{"variant":"tonal","color":"#0077d5"},on:{"click":function($event){_vm.dialogLogout = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.no'))+" ")])],1)],1)],1),_c('v-app-bar',{class:_vm.fromLogin ? 'login-style' : 'app-bar-style',staticStyle:{"z-index":"99"},attrs:{"elevation":_vm.fromLogin ? 0 : undefined,"shadow":_vm.fromLogin ? false : undefined,"floating":_vm.fromLogin ? false : true,"app":_vm.fromLogin ? false : true,"dense":_vm.fromLogin ? false : true,"fixed":_vm.fromLogin ? false : true}},[_c('v-toolbar-title',{staticClass:"d-flex align-center mr-8"},[(
          !(_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.xl) && !_vm.fromLogin
        )?_c('button',{on:{"click":function($event){return _vm.$emit('toggle-drawer')}}},[_c('v-icon',{staticStyle:{"font-size":"32px"},attrs:{"color":"white"}},[_vm._v("mdi-menu")])],1):_vm._e(),_c('v-img',{attrs:{"width":_vm.fromLogin ? '150px' : '100px',"contain":"","src":require('@/../public/img/logo.png')},on:{"click":function($event){return _vm.navigateHome()}}}),_c('div',[_c('span',{class:_vm.$i18n.locale === 'tr' ? 'activeLanguage' : 'passiveLanguage',attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.changeLanguage('tr')}}},[_vm._v("TR")]),_c('span',{class:_vm.$i18n.locale === 'en' ? 'activeLanguage' : 'passiveLanguage',attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.changeLanguage('en')}}},[_vm._v("EN")])])],1),(_vm.$store.state.Token)?_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.$store.state.PortalUserId && !_vm.$store.state.isPrevAdmin)?_c('v-btn',{staticClass:"darken-1",staticStyle:{"color":"#fff","text-transform":"unset !important","background-color":"var(--red)","padding":"1vh 1vh","border-radius":"30px !important","font-weight":"bolder","font-size":"small","margin-right":"10px"},attrs:{"elevation":"0"},on:{"click":_vm.returnUser}},[_vm._v(" "+_vm._s(_vm.$t('labels.returnToUserList'))+" ")]):_vm._e(),(
          _vm.$store.getters.getUserType !== 'Unassigned' &&
          _vm.$store.getters.getUserType !== 'Admin'
        )?_c('v-btn',{attrs:{"icon":"","large":"","color":"white","id":"menu-activator"},on:{"click":function($event){_vm.$route.name === 'messages'
            ? null
            : _vm.$router.push({ name: 'messages' })}}},[_c('v-badge',{staticStyle:{"cursor":"pointer"},attrs:{"overlap":"","content":_vm.unSeenCount,"left":"","offset-x":"16","offset-y":"16","color":"red","value":_vm.unSeenCount > 0}},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","large":"","color":"white"},on:{"click":function($event){_vm.dialogLogout = true}}},[_c('v-icon',[_vm._v("mdi-logout")])],1)],1):_vm._e(),(_vm.fromLogin)?_c('div',{staticClass:"flex w-full h-full mt-14 mx-2 mx-xs-2 mx-sm-4 mx-md-8 mx-lg-12 mx-xl-14 mx-xxl-16",staticStyle:{"border-bottom-width":"2px","border-bottom-color":"#e0e0e0","border-bottom-style":"solid","opacity":"0.75"}}):_vm._e(),(!_vm.$store.state.Token)?_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"button",staticStyle:{"margin-right":"10px"},attrs:{"variant":"flat","dark":"","color":"var(--pink)"},on:{"click":function($event){return _vm.$router.push({ name: 'register' })}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.register'))+" ")]),_c('v-btn',{staticClass:"button",attrs:{"variant":"flat","dark":"","color":"var(--turquoise)"},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.login'))+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }