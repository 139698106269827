<template>
  <v-card elevation="1" class="mt-0 py-lg-10" style="margin: 0 !important">
    <v-dialog v-model="addChartEntryModal" @input="resetModal" max-width="800">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-sm-center popupHeadline" style="width: 100%">
            {{ $t('labels.addNew') }}
          </div>
        </v-card-title>

        <v-divider class="mb-4" />

        <ValidationObserver
          ref="addChartEntryObserver"
          v-slot="{ handleSubmit }"
        >
          <form autocomplete="off" @submit.prevent="handleSubmit(add)">
            <div style="position: absolute; top: 12px; right: 10px">
              <v-btn
                @click="handleDownload(1)"
                class="rounded-circle mr-2"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                  background-color: #0177d4;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-file-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.samplePriceExcelDownload') }}</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                @click="handleDownload(2)"
                class="rounded-circle mr-2"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                  background-color: #0177d4;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-file-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.sampleRegionExcelDownload') }}</span>
                </v-tooltip>
              </v-btn>
            </div>

            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                  <input
                    type="radio"
                    id="one"
                    value="1"
                    style="
                      width: 20px;
                      height: 20px;
                      accent-color: var(--mainColor);
                      margin-right: 10px;
                    "
                    v-model="form.exportImportType"
                  />
                  <label
                    style="
                      display: inline-block;
                      padding: 5px 10px;
                      border-radius: 15px;
                      background-color: var(--mainColor);
                      color: white;
                      cursor: pointer;
                    "
                    class="custom-label-form-element"
                    for="one"
                    >{{ $t('labels.export') }}</label
                  >
                </v-col>
                <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                  <input
                    type="radio"
                    id="two"
                    value="0"
                    style="
                      width: 20px;
                      height: 20px;
                      accent-color: var(--mainColor);
                      margin-right: 10px;
                    "
                    v-model="form.exportImportType"
                  />
                  <label
                    style="
                      display: inline-block;
                      padding: 5px 10px;
                      border-radius: 15px;

                      background-color: var(--mainColor);
                      color: white;
                      cursor: pointer;
                    "
                    class="custom-label-form-element"
                    for="one"
                    >{{ $t('labels.import') }}</label
                  >
                </v-col>
                <FormElement
                  key="ENTRY_NAME"
                  :vertical="true"
                  v-model="form.name"
                  :validationLabel="$t('labels.name')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.name')"
                />

                <FormElement
                  key="ENTRY_COUNTRY"
                  :vertical="true"
                  v-model="form.country"
                  :validationLabel="$t('labels.country')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: countries,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('labels.country')"
                />

                <FormElement
                  key="ENTRY_CURRENCY"
                  :vertical="true"
                  v-model="form.currency"
                  :validationLabel="$t('labels.currency')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencies,
                      value: 'Code',
                      text: 'Code',
                    },
                  }"
                  :placeholder="$t('labels.currency')"
                />

                <FormElement
                  key="ENTRY_REGIONS"
                  :vertical="true"
                  v-model="form.regionsFile"
                  :validationLabel="$t('labels.region')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'file',
                    type: '',
                    data: {
                      accept:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    },
                  }"
                  :placeholder="$t('labels.region')"
                />

                <FormElement
                  key="ENTRY_PRICES"
                  :vertical="true"
                  v-model="form.pricesFile"
                  :validationLabel="$t('labels.price')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'file',
                    type: '',
                    data: {
                      accept:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    },
                  }"
                  :placeholder="$t('labels.price')"
                />

                <FormElement
                  key="ENTRY_SHIPMENT_TYPE"
                  :vertical="true"
                  v-model="form.shipmentType"
                  :validationLabel="$t('titles.shipmentType')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'select',
                    items: {
                      data: shipmentTypes,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('titles.shipmentType')"
                />

                <FormElement
                  key="ENTRY_EXPIRY_DATE"
                  v-model="form.expiryDate"
                  :validationLabel="$t('labels.expiryDate')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'date',
                    dateMode: 'date',
                  }"
                  :placeholder="$t('labels.expiryDate')"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.add') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="resetModal()"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.partialTransportation') }}</h5>
        </v-col>
      </v-row>

      <v-icon
        small
        class="mr-6"
        style="font-size: 32px"
        @click="addChartEntryModal = true"
      >
        mdi-plus-box-outline
      </v-icon>
    </v-card-title>
    <v-divider />
    <v-data-table
      :headers="chartEntryHeaders"
      :items="items"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :item-key="'Id'"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.ExportImportType="{ item }">
        <v-chip
          v-if="item.ExportImportType == 0"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ $t('labels.import') }}
        </v-chip>
        <v-chip
          v-if="item.ExportImportType == 1"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ $t('labels.export') }}
        </v-chip>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row align="center" justify="center">
            <v-col
              v-if="
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.xl
              "
              md="2"
            ></v-col>
            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col
              cols="11"
              sm="11"
              md="3"
              lg="3"
              xl="3"
              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
            >
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                hide-details
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noData') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->
      <template v-slot:item.isExpired="{ item }">
        <v-chip
          class="ma-2"
          small
          text-color="white"
          :color="item.PriceListStatus === 0 ? 'green' : item.PriceListStatus === 1 ? 'red' : '#B2B2B2'"
        >
          {{ $t('labels.' + (item.PriceListStatus === 0 ? 'isActive' : item.PriceListStatus === 1 ? 'tooltipPassive' : 'expired')) }}
        </v-chip>
      </template>
      <template v-slot:item.ExpiryDate="{ item }">
        {{ convertToLocal(item.ExpiryDate) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({
                    name: 'partialTransportationDetail',
                    params: { item },
                  })
                "
              >
                mdi-arrow-right
              </v-icon>
            </template>
            <span>{{ $t('buttons.goToDetails') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import PartialTransportationServices from '../services/PartialTransportationServices';
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCountries,
  getCurrencyTypes,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import { shipmentTypes } from '../utils/constants';

export default {
  data() {
    return {
      addChartEntryModal: false,
      currencies: [],
      countries: [],
      chartEntryHeaders: [],
      items: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      shipmentTypes,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      addChartEntryModal: false,
      form: {
        name: '',
        cargo: '',
        country: '',
        currency: '',
        shipmentType: 2,
        regionsFile: null,
        pricesFile: null,
        expiryDate: new Date().toISOString().slice(0, 10),
        exportImportType: 1,
      },
    };
  },
  components: { FormElement },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },

  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    handleDownload(option) {
      const downloadUrl1 =
        'https://algonder.s3.amazonaws.com/Documents/30.05.2024%2010:23:49Parsiyel-Fiyat.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1748589830&Signature=Qdhf34Sd95jkZcs7rGyLS6cSELk%3D';
      const downloadUrl2 =
        'https://algonder.s3.amazonaws.com/Documents/30.05.2024%2012:17:36Parsiyel-B%C3%B6lge-Listesi.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1748596657&Signature=ygDiZjh0dSyPY2nIDeetAY1qm%2Fs%3D';

      if (option === 1) {
        window.open(downloadUrl1, '_blank');
      } else if (option === 2) {
        window.open(downloadUrl2, '_blank');
      }
    },
    async add() {
      this.$store.state.loadingStatus = true;

      let body = {
        ListName: this.form.name,
        CountryId: this.form.country,
        CurrencyCode: this.form.currency,
        Regions: this.form.regionsFile,
        Prices: this.form.pricesFile,
        ShipmentType: this.form.shipmentType,
        ExpiryDate: new Date(this.form.expiryDate).toISOString(),
        ExportImportType: this.form.exportImportType,
      };

      PartialTransportationServices.add(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.successfulAddExpress'));
          this.form = {};
          this.addChartEntryModal = false;
          this.getHistory();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getHistory();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getHistory();
    },

    async setup() {
      this.currencies = await getCurrencyTypes();
      this.countries = await getCountries();
      this.getHistory();
      this.chartEntryHeaders = [
        {
          text: this.$t('labels.name'),
          value: 'ListName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.direction'),
          value: 'ExportImportType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'CreatedAt',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.currency'),
          value: 'CurrencyCode',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.expiryDate'),
          value: 'ExpiryDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.expiryStatus'),
          value: 'isExpired',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.tableActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ];
    },

    /**
     * Get search history from service.
     */
    getHistory() {
      this.$store.state.loadingStatus = true;
      PartialTransportationServices.getPriceList(this.page, this.itemsPerPage)
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    resetModal() {
      this.$refs.addChartEntryObserver.reset();
      this.form = {
        name: '',
        cargo: '',
        country: '',
        currency: '',
        shipmentType: 2,
        regionsFile: null,
        pricesFile: null,
        expiryDate: new Date().toISOString().slice(0, 10),
        exportImportType: 1,
      };
      this.addChartEntryModal = false;
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
