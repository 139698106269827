<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t('navigationDrawerMenu.marketplaceOrders') }}</h3>
        </v-col>
      </v-row>

      <div>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </div>
    </v-card-title>
    <v-divider />

    <v-row class="mt-4 px-4 d-flex justify-end">
      <FormElement
        key="SEARCH_QUERY"
        v-model="searchQuery"
        :vertical="true"
        hide-details
        :validationLabel="$t('labels.search')"
        :topLabel="true"
        :labelShown="false"
        :cols="12"
        :sm="4"
        :lg="3"
        :formType="{
          name: 'text',
          clearable: true,
        }"
        :onChange="getItems"
        :placeholder="$t('labels.search')"
        :classes="'mb-4 mb-sm-0'"
      />

      <FormElement
        key="MARKETPLACE"
        v-model="marketplaceId"
        :vertical="true"
        hide-details
        :validationLabel="$t('labels.marketplaceLabel')"
        :topLabel="true"
        :labelShown="false"
        :cols="6"
        :sm="4"
        :lg="3"
        :onChange="getItems"
        :formType="{
          name: 'multipleSelect',
          items: {
            data: marketplaces,
            text: 'Name',
            value: 'Id',
          },
        }"
        :placeholder="$t('labels.marketplaceLabel')"
      />
      <FormElement
        key="STATUS"
        :vertical="true"
        v-model="status"
        hide-details
        :validationLabel="$t('labels.status')"
        :topLabel="true"
        :labelShown="false"
        :cols="6"
        :sm="4"
        :lg="3"
        :onChange="getItems"
        :formType="{
          name: 'multipleSelect',
          items: {
            data: statuses,
            text: 'Name',
            value: 'Id',
          },
        }"
        :placeholder="$t('labels.status')"
      />
    </v-row>
    <!--         
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left" lg="4" cols="12">
          <h5>{{ $t('navigationDrawerMenu.marketplaceOrders') }}</h5>
        </v-col>
      </v-row>
      <v-btn
        color="green"
        class="action-button button my-2 ml-2"
        @click="refreshOrders"
      >
        <v-icon left>mdi-refresh</v-icon>
        {{ $t('buttons.refreshOrders') }}
      </v-btn>
      <span
        v-if="false"
        style="font-size: 11px; font-weight: normal; color: var(--lightRed)"
        >{{ $t('messages.noRemainingRefreshCount') }}</span
      >
    </v-card-title> -->
    <v-divider />

    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :item-key="'Id'"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row align="center" justify="center">
            <v-col
              v-if="
                $vuetify.breakpoint.md ||
                $vuetify.breakpoint.lg ||
                $vuetify.breakpoint.xl
              "
              md="2"
            ></v-col>
            <v-col cols="9" sm="12" md="7" lg="7" xl="7">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col
              cols="11"
              sm="11"
              md="3"
              lg="3"
              xl="3"
              class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
            >
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noData') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.OrderCurrentStatus="{ item }">
        <v-chip
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ item.OrderCurrentStatus }}
        </v-chip>
      </template>
      <template v-slot:item.Receiver="{ item }">
        {{ (item.ReceiverName || '') + ' ' + (item.RecieverSurname || '') }}
      </template>
      <template v-slot:item.OrderAmount="{ item }">
        <v-chip
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          <span class="bold-cell">{{
            item.OrderAmount + ' ' + item.Currency
          }}</span>
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({
                    path: `quick-search/${item.Id}`,
                  })"
              >
                mdi-magnify
              </v-icon>
            </template>
            <span>{{ $t('buttons.search') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import IntegrationServices from '../services/IntegrationServices';
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCountries,
  getCurrencyTypes,
  getMarketplaces,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';

export default {
  data() {
    return {
      currencies: [],
      countries: [],
      marketplaces: [],
      headers: [],
      items: [],
      statuses: [],

      // Filters
      searchQuery: '',
      marketplaceId: '',
      status: '',

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
    };
  },
  components: { FormElement, RefreshButton },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
  },
  watch: {
    addChartEntryModal(newValue) {
      if (!newValue) {
        this.$refs.addChartEntryObserver.reset();
        this.form = {};
      }
    },
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    refreshOrders() {
      this.$store.state.loadingStatus = true;
      IntegrationServices.addOrder()
        .then((response) => {
          this.$store.state.RequestCount--;
          window.showSuccess(this.$t('messages.updateIntegrationOrders'));
        })
        .catch((error) => {
          console.log('ERROR >> ', error);
          window.handleServiceError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    async setup() {
      this.currencies = await getCurrencyTypes();
      this.countries = await getCountries();
      this.marketplaces = await getMarketplaces();

      this.getItems();
      this.headers = [
        {
          text: this.$t('labels.orderId'),
          value: 'OrderNo',
          sortable: false,
          groupable: false,
          cellClass: 'color-pink',
          align: 'center',
        },
        {
          text: this.$t('labels.buyer'),
          value: 'Receiver',
          sortable: false,
          groupable: false,
          cellClass: 'color-pink',
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'OrderDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'OrderAmount',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.status'),
          value: 'OrderCurrentStatus',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        },
      ];
    },

    /**
     * Get search history from service.
     */
    getItems() {
      this.$store.state.loadingStatus = true;
      IntegrationServices.getAll({
        page: this.page,
        pageSize: this.itemsPerPage,
        Search: this.searchQuery,
        MarketplaceId: this.marketplaceId,
        Status: this.status,
      })
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style></style>
