<template>
  <div>
    <!-- Marketplace Modal -->
    <v-dialog persistent v-model="marketPlaceModal" max-width="600">
      <v-card>
        <v-card-title class="headline update">
          <div class="text-center white--text" style="width: 100%">
            {{ this.modalHeader }}
          </div>
        </v-card-title>
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(sendRoleForm)">
            <v-card-text class="py-3">
              <v-row class="align-center pt-4">
                <v-col
                  v-if="isUpdate"
                  class="mb-4 form-column d-flex"
                  cols="12"
                  lg="12"
                >
                  <v-img
                    v-if="
                      marketplace.PhotoUrl ||
                      base64 ||
                      require('../../assets/no-image.png')
                    "
                    :lazy-src="
                      base64
                        ? base64
                        : marketplace.PhotoUrl ||
                          require('../../assets/no-image.png')
                    "
                    max-height="50"
                    max-width="50"
                    :src="base64 ? base64 : marketplace.PhotoUrl"
                  ></v-img>
                  <div class="ml-4 align-self-center d-flex flex-column">
                    <span class="form-label">
                      {{ marketplace.MarketplaceName }}
                    </span>
                    <div v-if="parameters[0]">
                      <span style="line-height: 2.2; color: #315a88"
                        >{{ $t('labels.marketplace.' + parameters[0]) }}:
                      </span>
                      <span style="line-height: 2.2; color: #315a88">{{
                        marketplace[parameters[0]]
                      }}</span>
                    </div>
                  </div>
                </v-col>

                <FormElement
                  v-model="marketplaceId"
                  :disabled="isUpdate"
                  :validationLabel="$t('labels.type', { value: 'Pazaryeri' })"
                  rules="required"
                  :formLabel="$t('labels.marketplace.title')"
                  :onChange="marketplaceChanged"
                  :formType="{
                    name: 'select',
                    items: {
                      data: marketplaces,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('labels.marketplace.title')"
                />

                <FormElement
                  v-model="marketplace.WarehouseId"
                  :active="true"
                  :validationLabel="$t('labels.warehouse')"
                  rules="required"
                  :formLabel="$t('labels.warehouse')"
                  :formType="{
                    name: 'select',
                    items: {
                      data: warehouses,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('labels.warehouse')"
                />

                <v-col v-if="loadings.marketplace" class="mb-4 text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
                <!-- eslint-disable -->
                <FormElement
                  v-if="!loadings.marketplace"
                  v-for="(element, index) in parameters"
                  :key="index"
                  v-model="marketplace[element]"
                  :active="true"
                  :validationLabel="$t('labels.marketplace.' + element)"
                  rules="required"
                  :formLabel="$t('labels.marketplace.' + element)"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.marketplace.' + element)"
                />
                <!-- eslint-enable -->
                <!-- COLUMN -->
              </v-row>
            </v-card-text>

            <v-card-actions
              style="
                background-color: white;

                position: sticky;

                bottom: 0px;

                border-top: solid grey 2px;
              "
            >
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                class="update darken-1"
                dark
                type="submit"
                :loading="loadings.send"
              >
                {{ modalHeader }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="
                  marketPlaceModal = false;
                  clearModal();
                  marketplace = temporaryMarketplace;
                "
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Marketplace Modal -->

    <delete-modal :yesFunc="deleteCompanyMarket" ref="deleteModalRef" />
    <v-card elevation="2" style="border-radius: 0">
      <v-card-title class="popupHeadline">
        <h4>Pazaryeri Yönetimi</h4>
        <v-spacer />
        <v-row class="d-lg-flex">
          <v-col>
            <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              clearable
              hide-details
              hide-selected
              item-text="MarketplaceName"
              item-value="Id"
              label="Arama"
              rounded
              filled
              append-icon="mdi-magnify"
              prepend-icon="mdi-account-key"
              solo
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  @click="changeActiveCompanyMarket(item, true)"
                  v-on="on"
                >
                  <v-icon left> mdi-account </v-icon>
                  <v-icon left> mdi-account </v-icon>
                  <span v-text="item.MarketplaceName"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="text-h5 font-weight-light white--text"
                >
                  <v-img
                    :lazy-src="
                      item.PhotoUrl || require('../../assets/no-image.png')
                    "
                    max-height="50"
                    max-width="50"
                    :src="item.PhotoUrl || require('../../assets/no-image.png')"
                  ></v-img>
                </v-list-item-avatar>
                <!-- eslint-disable -->
                <v-list-item-content
                  @click="changeActiveCompanyMarket(item, true)"
                >
                  <v-list-item-title
                    v-text="item.MarketplaceName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.MarketplaceName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <!-- eslint-enable -->
                <v-list-item-action>
                  <v-icon>mdi-shopping</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
          <span
            class="align-self-center mr-2"
            style="font-size: 12px; font-weight: normal; color: ##598db5"
            >Pazaryeri Ekle</span
          >
          <v-icon
            @click="
              marketPlaceModal = true;
              parameters = [];
              modalHeader = $t('title.add', { value: 'Pazaryeri' });
            "
            class="icon-button mr-4 align-self-center"
          >
            mdi-plus
          </v-icon>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pt-4">
        <v-data-table
          :headers="headers"
          :items="companyMarketplaces"
          :loading="$store.state.loadingStatus"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :hide-default-footer="true"
          :no-data-text="$t('description.noList')"
        >
          <!-- eslint-disable -->
          <template v-slot:item.PhotoUrl="{ item }">
            <v-row style="padding: 10px">
              <v-col>
                <v-img
                  :lazy-src="
                    item.PhotoUrl || require('../../assets/no-image.png')
                  "
                  max-height="50"
                  max-width="50"
                  :src="item.PhotoUrl || require('../../assets/no-image.png')"
                ></v-img>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.MarketplaceName="{ item }">
            {{ item.MarketplaceName }}
          </template>
          <template v-slot:item.CreateBy="{ item }">
            {{ item.CreateBy }}
          </template>
          <template v-slot:item.Status="{ item }">
            <v-chip
              style="margin-left: -6px"
              v-if="item.Status == 'Aktive'"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.tooltipActive') }}
            </v-chip>

            <v-chip
              v-else
              class="text--center"
              style="margin-left: -6px"
              small
              :color="item.Status === 'Passive' ? '#e3e2e3' : '#CF181F'"
              :text-color="item.Status === 'Passive' ? '#2b3838' : '#FFF'"
            >
              {{
                $t(
                  item.Status === 'Passive'
                    ? 'labels.tooltipPassive'
                    : 'labels.authorizationFailed'
                )
              }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="item.Status !== 'Aktive'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  middle
                  class="mr-2"
                  color="red"
                  @click="test(item.Id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-table-merge-cells
                </v-icon>
              </template>
              <span>{{ $t('labels.test') }}</span>
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  middle
                  class="mr-2"
                  color="green"
                  @click="changeActiveCompanyMarket(item, true)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-edit
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  middle
                  class="mr-2"
                  @click="openDeleteModal(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipDelete') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <!-- Pagination select box start -->
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="pagination.page"
                :length="pages"
                :total-visible="15"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="itemsPerPage"
                :items="$store.state.perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                @change="changeItemsPerPage()"
                outlined
                dense
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- Pagination select box end -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteModal from '../../components/General/DeleteModal';
import TabScreen from '../../components/TabScreen.vue';
import MarketplaceServices from '../../services/MarketplaceServices';
import CompanyMarketplaceServices from '../../services/CompanyMarketplaceServices';
import FormElement from '../../components/FormElement.vue';
import WarehouseServices from '../../services/WarehouseServices';

export default {
  data: () => ({
    // General Models
    isLoading: false,
    isUpdate: false,
    isUploading: false,
    file: null,
    base64: '',

    parameters: [],

    marketplaceId: '',
    marketplace: {},
    model: null,
    search: null,
    marketplaces: [],
    warehouses: [],
    companyMarketplaces: [],
    deletedId: -1,
    company: {},

    marketPlaceModal: false,

    // List options
    options: {
      itemsPerPage: 10,
    },
    headers: [],
    //Loading states
    loadings: {
      send: false,
      marketplace: false,
    },
    modalHeader: '', //Global header for opened modal
    pagination: {},
    itemsPerPage: 10,
  }),

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) {
        return 0;
      }

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.companyMarketplaces.map((entry) => {
        return Object.assign({}, entry, {
          Id: entry.Id,
          MarketplaceName: entry.MarketplaceName,
        });
      });
    },
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      this.getCompanyMarketPlaces();
    },
  },
  mounted() {
    this.setup();
  },
  components: {
    DeleteModal,
    TabScreen,
    FormElement,
  },
  methods: {
    noOperation() {
      this.clearModal();
      this.resetForm();
    },

    changeItemsPerPage() {
      this.pagination.rowsPerPage = this.itemsPerPage;
      this.pagination.page = 1;
      this.options.itemsPerPage = this.itemsPerPage;
      this.getCompanyMarketPlaces();
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.page = pageNumber;
      this.rowsPerPage = this.itemsPerPage;
      this.getCompanyMarketPlaces();
    },

    /**
     * Adds a new marketplace integraton for company.
     */
    addCompanyMarket() {
      this.loadings.send = true;
      this.marketplace.MarketplaceId = this.marketplaceId;
      CompanyMarketplaceServices.add(this.marketplace)
        .then((res) => {
          this.marketPlaceModal = false;
          window.showSuccess(
            this.$t('messages.successfulAdd', { value: 'Pazaryeri' })
          );
          this.clearModal();
          this.getCompanyMarketPlaces();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .then(() => {
          this.loadings.send = false;
        });
    },

    /**
     * Test Company Market which one you have added into Company Market List.
     ** FIXME: Her türlü veriye "Success" dönüyor. Servis tarafında düzeltilecek.
     * @param {string} companyMarketId
     */
    test(companyMarketId) {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.test(companyMarketId)
        .then((response) => {
          if (response.data.Succeed) {
            window.showSuccess(this.$t('messages.successfulTest'));
            this.getCompanyMarketPlaces();
          } else {
            window.showSuccess(this.$t('messages.errorTest'));
          }
        })
        .catch((error) => {
          window.handleServiceError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Update an api integration for company.
     */
    updateCompanyMarket() {
      this.loadings.send = true;

      var body = {
        CompanyMarketId: this.marketplace.Id,
        WarehouseId: this.marketplace.WarehouseId,
      };

      this.parameters.forEach((item) => (body[item] = this.marketplace[item]));

      CompanyMarketplaceServices.update(body)
        .then((res) => {
          this.marketPlaceModal = false;
          window.showSuccess(
            this.$t('messages.successfulUpdate', { value: 'Pazaryeri' })
          );
          this.getCompanyMarketPlaces();
          this.clearModal();
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.loadings.send = false;
        });
    },

    //Page setup
    setup() {
      this.getMarketPlaceList();
      this.getWarehouseList();
      this.getCompanyMarketPlaces();
      this.pagination = {
        totalItems: 0,
        rowsPerPage: 10,
        page: 1,
      };

      this.modalHeader = this.$t('title.add', { value: 'Yetki' });
      this.headers = [
        {
          text: this.$t('labels.logo'),
          value: 'PhotoUrl',
          sortable: false,
        },
        {
          text: this.$t('labels.marketplace.title'),
          value: 'MarketplaceName',
          sortable: false,
        },
        {
          text: this.$t('labels.creator'),
          value: 'CreateBy',
          sortable: false,
        },
        {
          text: this.$t('labels.status'),
          value: 'Status',
          sortable: false,
        },
        {
          text: this.$t('labels.actions'),
          value: 'actions',
          sortable: false,
        },
      ];
    },

    //Clears all modal's states and item ID
    clearModal() {
      setTimeout(() => {
        this.modalHeader = '';
        this.resetForm();
        this.isUpdate = false;
        this.marketplaceId = '';
        this.marketplace = {
          ApiUserName: '',
          ApiKey: '',
          ApiEndPoint: '',
          Status: '',
          SecretKey: '',
          RefreshToken: '',
          MerchantId: '',
          RoleArn: '',
          MerchantSecret: '',
          MarketplaceId: '',
          CommissionRate: 0,
          TaxRate: 0,
        };
      }, 500);
    },

    /**
     * Change active role of the page.
     * @param {*} item
     * @param {boolean} isUpdate
     */
    async changeActiveCompanyMarket(item, isUpdate = false) {
      this.marketplaceId = item.MarketplaceId;
      await this.marketplaceChanged();

      this.modalHeader = this.$t('title.update', { value: 'Pazaryeri' });
      this.marketPlaceModal = true;
      this.marketplace = { ...item };
      this.isUpdate = isUpdate;
    },

    /**
     * Get role list.
     */
    getCompanyMarketPlaces() {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.get(
        this.$store.state.CompanyId,
        this.pagination.page,
        this.pagination.rowsPerPage
      )
        .then((res) => {
          this.companyMarketplaces = res.data.Result;
          this.pagination.totalItems = res.data.TotalItems;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(this.onFinal);
    },

    /**
     * Final Function Of Services
     */
    onFinal() {
      this.$store.state.loadingStatus = false;
    },

    /**
     * Get warehouse list.
     */
    getWarehouseList() {
      this.$store.state.loadingStatus = true;
      WarehouseServices.getAll()
        .then((res) => {
          this.warehouses = res.data.Result;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(this.onFinal);
    },

    /**
     * Get marketplace list.
     */
    getMarketPlaceList() {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.getPurchasedList()
        .then((res) => {
          this.marketplaces = res.data;
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(this.onFinal);
    },

    /**
     * Add or update a role.
     */
    sendRoleForm() {
      if (this.isUpdate) {
        this.updateCompanyMarket();
      } else {
        this.addCompanyMarket();
      }
    },

    /**
     * onChangeImage trigger.
     */
    onChangeImage() {
      if (this.file) {
        var reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
        this.isUploading = true;
      } else {
        this.isUploading = false;
      }
    },

    /**
     * Delete a role.
     */
    deleteCompanyMarket() {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.delete(this.deletedId)
        .then(() => {
          this.$refs.deleteModalRef.modalStatus = false;
          window.showSuccess(
            this.$t('messages.successfulDelete', { value: 'Pazaryeri' })
          );
          this.getCompanyMarketPlaces();
        })
        .catch((err) => {
          window.handleServiceError(err);
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * If marketplace changed then trigger this.
     */
    async marketplaceChanged() {
      if (this.marketplaceId) {
        this.loadings.marketplace = true;
        MarketplaceServices.getParameters(this.marketplaceId)
          .then((response) => {
            this.parameters = response.data.Result;
          })
          .catch((error) => {
            console.log('Get Parameters => ', error);
          })
          .finally(() => (this.loadings.marketplace = false));
      } else {
        console.log('MarketplaceId Error => ', this.marketplaceId);
      }
    },

    /**
     * Shows delete modal.
     * @param {*} item
     */
    openDeleteModal(item) {
      this.deletedId = item.Id;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    /**
     * Resetting forms' validation.
     */
    async resetForm() {
      this.$refs.observer.reset();
    },
  },
};
</script>
