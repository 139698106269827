<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="d-flex text-lg-left text-center align-center" lg="12" cols="12">
          <v-icon
            @click.stop="$router.push({ name: 'dynamicReports' })"
            style="color: #0177d4; font-size: 36px"
            class="mr-2"
          >
            mdi-arrow-left
          </v-icon>
          <h3>{{ reportName }}</h3>
        </v-col>
      </v-row>
      <div>
        <v-btn
          @click="downloadExcelFile"
          class="rounded-circle mr-2"
          style="
            min-width: 1px;
            min-height: 1px;
            width: 36px;
            height: 36px;
            background-color: #0177d4;
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                color="white"
                style="font-size: 20px"
                v-on="on"
              >
                mdi-arrow-down
              </v-icon>
            </template>
            <span>{{ $t('buttons.download') }}</span>
          </v-tooltip>
        </v-btn>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row class="mb-4 mt-0">
        <FormElement
          key="START_DATE_FILTER"
          :vertical="true"
          v-model="filter.StartDate"
          hide-details
          :validationLabel="$t('labels.startDate')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          @input="getItems"
          :formType="{
            name: 'date',
            dateMode: 'date',
          }"
          :placeholder="$t('labels.startDate')"
        />
        <FormElement
          key="END_DATE_FILTER"
          :vertical="true"
          v-model="filter.EndDate"
          hide-details
          :validationLabel="$t('labels.endDate')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          @input="getItems"
          :formType="{
            name: 'date',
            dateMode: 'date'
          }"
          :placeholder="$t('labels.endDate')"
        />
      </v-row>
      <v-divider />
      <v-data-table
        :headers="dynamicHeaders"
        :items="items"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        :item-key="'Id'"
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8">
            <v-row align="center" justify="center">
              <v-col
                v-if="
                  $vuetify.breakpoint.md ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.xl
                "
                md="2"
              ></v-col>
              <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                <v-pagination
                  v-if="pageLength > 0"
                  class="my-4"
                  @input="onPageChange"
                  v-model="page"
                  :length="pageLength"
                  :total-visible="10"
                  circle
                ></v-pagination>
              </v-col>
              <v-col
                cols="11"
                sm="11"
                md="3"
                lg="3"
                xl="3"
                class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
              >
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:no-data>
          <div class="my-10 d-flex flex-column align-center">
            <h4>{{ $t('messages.noData') }}</h4>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-for="element in dynamicHeaders">
              {{ getValidValue(item, element) }}
            </td>
          </tr>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'updateDynamicReports', params: { id: item.Id } })"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('buttons.update') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ name: 'viewDynamicReports', params: { id: item.Id } })"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t('titles.details') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    
  </v-card>
</template>
<script>
import {
  ITEMS_PER_PAGE_OPTIONS,
  downloadFile
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';
import ReportService from '../services/ReportService';

export default {
  data() {
    return {
      countries: [],
      items: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],

      // Dynamic Route
      reportHeadersData: [],
      sampleData: [],
      filterHeaders: [],
      dynamicReportsForm: {
        selectedColumns: []
      },
      reportId: "",
      reportName: "",
      filter:{
        StartDate: "",
        EndDate: ""
      }
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    // Checking Params
    const reportId = this.$route.params.id;
    if(reportId){
      this.reportId = reportId;
      this.getItems();
    }
    this.reportName = this.$route.params.name || this.$t('navigationDrawerMenu.dynamicReports');
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
  },
  computed: {
    dynamicHeaders(){
      if(this.items.length === 0)
        return []

      return Object.keys(this.items[0]).map(item => {
        return {
          text: item,
          value: item,
          sortable: false,
        };
      })
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    getValidValue(item, element){
      const value = item[element.value];

      if(typeof value === "number") return value;
      
      // Check If Date
      const dateValue = new Date(value)
      if(!isNaN(dateValue.getTime())){
        return convertToLocal(dateValue) 
      }

      return typeof  value === "object" ? '-' : item[element.value]
    },
    
    downloadExcelFile(){
      this.$store.state.loadingStatus = true;
      ReportService.downloadDynamicReports({ ReportId: this.reportId })
      .then(response => {
        downloadFile(response.data, `report_${this.reportName}_${new Date().toISOString()}`, 'xlsx')
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      })
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    /**
     * Get search history from service.
     */
    getItems() {
      this.$store.state.loadingStatus = true;
      console.log(this.filter);
      ReportService.getDataDynamicReports({
        ReportId: this.reportId,
        Page: this.page,
        PageSize: this.itemsPerPage,
        StartDate: this.filter.StartDate.split("T")[0],
        EndDate: this.filter.EndDate.split("T")[0]
      })
      .then((response) => {
        this.items = response.data.Result;
        this.totalItems = response.data.TotalItems;
      })
      .catch(error => {
        this.$router.push({ name: 'dynamicReports' })
        window.showError(error)
      })
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
    },
  },
};
</script>
<style></style>
