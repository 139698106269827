import Vue from 'vue';
import Router from 'vue-router';
import guard from './javascript/navigation-guard';

import dashboard from './views/Dashboard.vue';
import CompanyInformation from './views/Company/Information.vue';
import AddCompany from './views/Company/AddCompany.vue';
import SubscriptionManagement from './views/SubscriptionManagement/Main';
import CompanyManagement from './views/Company/Management.vue';
import Page404 from './views/Page404.vue';
import Login from './views/Login.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import Home from './views/Home.vue';
import OrderDetail from './views/OrderDetail.vue';
import ProductDetail from './views/Product/ProductDetail.vue';
import Orders from './views/Orders.vue';
import UserGuide from './views/UserGuide.vue';
import Brands from './views/Brands.vue';
import Products from './views/Product/Products.vue';
import Reports from './views/Report.vue';
import AdminPackageManagment from './views/AdminPackageManagment.vue';
import StockManagement from './views/Product/StockManagement.vue';
import StockDetail from './views/Product/StockDetail.vue';
import Imports from './views/BulkOperations/Import.vue';
import Exports from './views/BulkOperations/Export.vue';
import Categories from './views/Categories.vue';
import Messages from './views/Messages.vue';
import Profile from './views/Profile.vue';
import Warehouse from './views/WarehouseManagement.vue';
import Package from './views/PackageManagement.vue';
import PaymentCompleted from './views/PaymentCompleted.vue';
import PackageRequests from './views/PackageRequests.vue';
import Logs from './views/Logs.vue';
import LogDetail from './views/LogDetail.vue';

import CreateOffer from './views/CreateOffer.vue';
import OfferResults from './views/OfferResults.vue';
import SearchHistory from './views/SearchHistory.vue';
import SpotPriceRequests from './views/SpotPriceRequests.vue';
import SpotOffers from './views/SpotOffers.vue';
import QuickSearch from './views/QuickSearch.vue';
import RateSupplier from './views/RateSupplier.vue';
import MessageDetail from './views/MessageDetail.vue';
import Payments from './views/Payments.vue';
import PaymentSummary from './views/PaymentSummary.vue';

import LegalInfo from './views/LegalInfo.vue';
import Support from './views/Support.vue';
import Settings from './views/Settings.vue';
import Address from './views/Address.vue';
import FAQ from './views/FAQ.vue';
import Marketplace from './views/Marketplace.vue';
import IntegrationManagement from './views/IntegrationManagement.vue';
import PortalManagement from './views/PortalManagement.vue';
import Accounting from './views/Accounting.vue';
import PlannerManagement from './views/PlannerManagement.vue';

import PriceChartEntry from './views/PriceChartEntry.vue';
import ViewPriceList from './views/ViewPriceList.vue';
import OutOfAreaDetail from './views/OutOfAreaDetail.vue';
import ConfirmEmail from './views/ConfirmEmail.vue';

import OutOfAreaEntry from './views/OutOfAreaEntry.vue';

import Users from './views/Users.vue';
import Companies from './views/Companies.vue';
import MarketplaceOrders from './views/MarketplaceOrders.vue';

import PartialTransportation from './views/PartialTransportation.vue';
import PartialTransportationDetail from './views/PartialTransportationDetail.vue';

import InvoiceRedirect from './views/InvoiceRedirect.vue';
import OrderRedirect from './views/OrderRedirect.vue';
import TimeOut from './views/TimeOut.vue';

import Blacklist from './views/Blacklist.vue';
import ApprovePayment from './views/ApprovePayment.vue';
import Dictionary from './views/Dictionary.vue';

import OauthPage from './views/OauthPage.vue';

import Library from './views/Library.vue';

import AdditionalCosts from './views/AdditionalCosts.vue';
import APIManagment from './views/APIManagment.vue';

import CurrencyManagement from './views/CurrencyManagement.vue';
import DynamicReports from './views/DynamicReports.vue';
import ViewDynamicReports from './views/ViewDynamicReports.vue';
import ActionsDynamicReports from './views/ActionsDynamicReports.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '*',
      name: '404',
      component: Page404,
    },
    {
      path: '/',
      name: 'createOffer',
      beforeEnter: guard.ifNotAuthenticated,
      component: CreateOffer,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: { operation: 'login' },
      beforeEnter: guard.ifNotAuthenticated,
    },
    {
      path: '/etsy-callback',
      name: 'etsy-callback',
      component: OauthPage,
    },
    {
      path: '/ups-callback',
      name: 'ups-callback',
      component: OauthPage,
    },
    {
      path: '/connect-portal/:supplierId/:portalId',
      name: 'connect-portal',
      component: OauthPage,
      props: true,
    },
    {
      path: '/legal-info/:type',
      name: 'legal-info',
      component: LegalInfo,
      beforeEnter: guard.ifNotAuthenticated,
    },
    {
      path: '/update-password',
      name: 'forgot',
      component: ForgotPassword,
      beforeEnter: guard.ifNotAuthenticated,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
      props: { operation: 'register' },
      beforeEnter: guard.ifNotAuthenticated,
    },
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/loading',
      redirect: '/AuthLoading',
    },
    {
      name: 'emailConfirmation',
      path: '/authentication/confirm-email',
      component: ConfirmEmail,
      beforeEnter: guard.ifNotAuthenticated,
      meta: { userType: ['Supplier', 'Customer'] },
    },
    /*
          Payment Routers
        */
    {
      name: 'paymentCompleted',
      path: '/payment-completed',
      component: PaymentCompleted,
      beforeEnter: guard.ifAuthenticated,
      meta: {
        userType: [
          'Supplier',
          'Customer',
          'PendingCustomer',
          'PendingSupplier',
        ],
      },
    },
    {
      name: 'invoiceRedirect',
      path: '/invoice-redirect/:id',
      component: InvoiceRedirect,
      meta: { userType: ['Supplier'] },
    },
    {
      name: 'orderRedirect',
      path: '/order-redirect/:id',
      component: OrderRedirect,
      meta: { userType: ['Supplier'] },
    },
    {
      path: '/home',
      name: 'home',
      redirect: '/home/dashboard',
      component: Home,
      children: [
        /* Spot Price Routes */
        {
          path: 'offer-results',
          name: 'offerResultsCustomer',
          component: OfferResults,
        },
        {
          path: '/offer-results/:id',
          name: 'offerResultsById',
          component: OfferResults,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer', 'PendingCustomer'] },
        },
        {
          name: 'searchHistory',
          path: 'search-history',
          component: SearchHistory,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Supplier', 'Customer', 'Unassigned', 'PendingCustomer'],
          },
        },
        {
          name: 'priceChartEntry',
          path: 'price-chart-entry',
          component: PriceChartEntry,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer', 'Unassigned'] },
        },
        {
          name: 'partialTransportation',
          path: 'partial-transportation',
          component: PartialTransportation,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier'] },
        },
        {
          name: 'outOfAreaEntry',
          path: 'out-of-area-entry',
          component: OutOfAreaEntry,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer', 'Unassigned'] },
        },
        {
          name: 'partialTransportationDetail',
          path: 'partial-transportation-detail',
          component: PartialTransportationDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier'] },
        },
        {
          name: 'viewPriceList',
          path: 'view-price-list',
          component: ViewPriceList,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer', 'Unassigned'] },
        },
        {
          name: 'outOfAreaDetail',
          path: 'out-of-area-detail',
          component: OutOfAreaDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer', 'Unassigned'] },
        },
        {
          name: 'spotOffers',
          path: 'spot-offers',
          component: SpotOffers,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Supplier', 'Customer', 'Unassigned', 'PendingCustomer'],
          },
        },
        {
          name: 'quickSearch',
          path: 'quick-search/:id',
          component: QuickSearch,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer', 'PendingCustomer'] },
        },
        {
          name: 'quickSearch',
          path: 'quick-search',
          component: QuickSearch,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer', 'PendingCustomer'] },
        },
        {
          name: 'spotPriceRequests',
          path: 'spot-price-requests/:id',
          component: SpotPriceRequests,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Unassigned',
              'PendingCustomer',
              'PendingSupplier',
            ],
          },
        },
        {
          name: 'spotPriceRequestsSupplier',
          path: 'spot-price-requests-supplier',
          component: SpotPriceRequests,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'PendingSupplier'] },
        },
        /* Spot Price Routes END */

        // Payments
        {
          name: 'payments',
          path: 'payments',
          component: Payments,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Customer',
              'Supplier',
              'PendingCustomer',
              'PendingSupplier',
            ],
          },
        },
        {
          name: 'paymentSummary',
          path: 'payment-summary',
          component: PaymentSummary,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer', 'PendingCustomer', 'Accountant'] },
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          component: dashboard,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Unassigned',
              'Admin',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },
        {
          name: 'library',
          path: 'library',
          component: Library,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Unassigned',
              'Admin',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },

        {
          name: 'additionalCosts',
          path: 'additional-costs',
          component: AdditionalCosts,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Accountant'] },
        },

        /*
          Company Router
        */
        {
          name: 'company-information',
          path: 'company-information',
          component: CompanyInformation,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },

        /*
          Package Router
        */
        {
          name: 'package',
          path: 'package',
          component: Package,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },

        /*
          Package Requests Router
        */
        {
          name: 'packageRequests',
          path: 'package-requests',
          component: PackageRequests,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },

        /*
          SubscriptionManagement Router
        */
        {
          name: 'subscriptionManagement',
          path: 'subscription-management',
          component: SubscriptionManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },

        /*
          Logs Router
        */
        {
          name: 'logs',
          path: 'logs',
          component: Logs,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },

        {
          name: 'logDetail',
          path: 'logs/:id',
          component: LogDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },

        {
          name: 'companyManagement',
          path: 'company-management',
          component: CompanyManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'OrderDetail',
          path: 'OrderDetail',
          props: { default: true, sidebar: false },
          component: OrderDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'PendingCustomer',
              'PendingSupplier',
              'Operation',
            ],
          },
        },
        {
          name: 'orders',
          path: 'orders',
          component: Orders,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'PendingCustomer',
              'PendingSupplier',
              'Operation',
              'Admin',
            ],
          },
        },
        {
          name: 'marketplace',
          path: 'marketplace',
          component: Marketplace,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer'] },
        },
        {
          name: 'integrationManagement',
          path: 'integration-management',
          component: IntegrationManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier'] },
        },
        {
          name: 'publicApi',
          path: 'public-api',
          component: APIManagment,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer'] },
        },
        {
          name: 'portalManagement',
          path: 'portal-management',
          component: PortalManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier'] },
        },
        {
          name: 'accounting',
          path: 'accounting',
          component: Accounting,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Accountant', 'Operation'] },
        },
        {
          name: 'plannerManagement',
          path: 'planner-management',
          component: PlannerManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Planner', 'Operation'] },
        },
        {
          name: 'warehouse',
          path: 'warehouse/:marketplaceId?',
          component: Warehouse,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Warehouse'] },
        },
        {
          name: 'userGuide',
          path: 'userGuide',
          component: UserGuide,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'brands',
          path: 'brands',
          component: Brands,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'messages',
          path: 'messages',
          component: Messages,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'PendingCustomer',
              'PendingSupplier',
            ],
          },
        },
        {
          name: 'addCompany',
          path: 'addCompany',
          component: AddCompany,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Unassigned'] },
        },
        {
          name: 'products',
          path: 'products',
          component: Products,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'reports',
          path: 'reports',
          component: Reports,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Admin'],
          },
        },
        
        {
          name: 'dynamicReports',
          path: 'dynamic-reports',
          component: DynamicReports,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Customer', 'Supplier'],
          },
        },
        {
          name: 'addDynamicReports',
          path: 'add-dynamic-reports',
          component: ActionsDynamicReports,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Customer', 'Supplier'],
          },
        },
        {
          name: 'updateDynamicReports',
          path: 'update-dynamic-reports/:id',
          component: ActionsDynamicReports,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Customer', 'Supplier'],
          },
        },
        {
          name: 'viewDynamicReports',
          path: 'view-dynamic-reports/:id',
          component: ViewDynamicReports,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Customer', 'Supplier'],
          },
        },
        {
          name: 'adminPackageManagment',
          path: 'admin-package-managment',
          component: AdminPackageManagment,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: ['Admin'],
          },
        },
        {
          name: 'stockmanagement',
          path: 'stockmanagement',
          component: StockManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'imports',
          path: 'imports',
          component: Imports,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'exports',
          path: 'exports',
          component: Exports,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'productDetail',
          path: 'productDetail',
          component: ProductDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'stockDetail',
          path: 'stockDetail',
          component: StockDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'categories',
          path: 'categories',
          component: Categories,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'profile',
          path: 'profile',
          component: Profile,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer', 'Planner'] },
        },
        {
          name: 'rateOrder',
          path: 'rate-order',
          component: RateSupplier,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier', 'Customer'] },
        },
        {
          name: 'messageDetail',
          path: 'message-detail',
          component: MessageDetail,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'PendingCustomer',
              'PendingSupplier',
            ],
          },
        },
        {
          name: 'support',
          path: 'support',
          component: Support,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Unassigned',
              'Supplier',
              'Customer',
              'Admin',
              'PendingCustomer',
              'PendingSupplier',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },
        {
          name: 'faq',
          path: 'faq',
          component: FAQ,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Admin',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },
        {
          name: 'dictionary',
          path: 'dictionary',
          component: Dictionary,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Admin',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },
        {
          name: 'settings',
          path: 'settings',
          component: Settings,
          beforeEnter: guard.ifAuthenticated,
          meta: {
            userType: [
              'Supplier',
              'Customer',
              'Planner',
              'Accountant',
              'Operation',
              'Warehouse',
            ],
          },
        },
        {
          name: 'address',
          path: 'address',
          component: Address,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer', 'PendingCustomer'] },
        },

        {
          name: 'users',
          path: 'users',
          component: Users,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Admin'] },
        },

        {
          name: 'companies',
          path: 'companies',
          component: Companies,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Admin'] },
        },
        {
          name: 'timeOut',
          path: 'timeOut',
          component: TimeOut,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Admin'] },
        },
        {
          name: 'marketplaceOrders',
          path: 'marketplace-orders',
          component: MarketplaceOrders,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Customer'] },
        },
        {
          name: 'currencyManagement',
          path: 'currency-management',
          component: CurrencyManagement,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Supplier'] },
        },
        {
          name: 'blacklist',
          path: 'blacklist',
          component: Blacklist,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Admin'] },
        },
        {
          name: 'approvePayment',
          path: 'approvePayment',
          component: ApprovePayment,
          beforeEnter: guard.ifAuthenticated,
          meta: { userType: ['Admin'] },
        },
      ],
      //beforeEnter: guard.ifAuthenticated,
      props: true,
    },
  ],
});
