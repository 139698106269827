import Vue from "vue";
import { extend, localize } from "vee-validate";
import { required, email, min, max, integer, regex, confirmed, numeric,min_value,max_value,double } from "vee-validate/dist/rules";
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import ar from "vee-validate/dist/locale/ar.json";
import en from "../assets/vee-validate/en.json";
import tr from "../assets/vee-validate/tr.json";
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
// Install required rule.
extend("required", required);

extend('requiredArray', {
  validate(value) {
    return {
      required: false,
      valid: ['', null, undefined].indexOf(value) === -1 && value.length > 0
    };
  },
  computesRequired: true
});

extend('onlyStringText', {
  validate(value) {
    return {
      valid: value.match(/^[a-zA-ZçÇğĞıİöÖşŞüÜ ]+$/),
    };
  },
  computesRequired: true
});

extend('phone', {
  validate(value) {
    return {
      valid: value.match(/^\+?\(\d{1,4}?[-.\s]?\(?\d{1,3}?\)\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/),
    };
  },
  computesRequired: true
});

extend('numericWithDelimiters', {
  validate(value) {
    return {
      valid: value.toString().match(/^(?!0$)([1-9][0-9]*([,.][0-9]+)*)?$/),
    };
  },
  computesRequired: true
});

extend('multipleEmail', {
  validate(value) {
    return {
      valid: value.match(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*;\s*)*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    };
  },
  computesRequired: true
});

extend('url', {
  validate(value) {
    return {
      valid: value.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/),
    };
  }
});

// Install email rule.
extend("email", email);
// Install min rule.
extend("min", min);
// Install min_value rule.
extend("min_value", min_value);
// Install max_value rule.
extend("max_value", max_value);
// Install max rule.
extend("max", max);
// Install integer rule.
extend("integer", integer);
// Install integer rule.
extend("numeric", numeric);
// Install integer rule.
extend("double", double);
// Install regex rule
extend('regex', regex);
// Install confirmed rule
extend('confirmedPassword', confirmed);
// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      password: "Password",
      rePassword: "Re-Password"
    },
    fields: {
      rePassword: {
        confirmedPassword: "Passwords do not match."
      }
    }
  },
  tr: {
    messages: tr.messages,
    names: {
      password: "Şifre",
      rePassword: "Tekrar Şifre",
      
    },
    fields: {
      rePassword: {
        confirmedPassword: "Şifre alanları eşleşmemektedir."
      }
    }
  }
});
let LOCALE = localStorage.getItem('language') || 'tr';
localize(LOCALE);
// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get() {
    return LOCALE;
  },
  set(val) {
    LOCALE = val;
    localize(val);
  }
});